<script setup>
import dayjs from 'dayjs';
import { cloneDeep, uniq } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useEvents } from '~/project-management/composables/pm-events.composable';
import { useProjectManagementStore } from '~/project-management/store/pm.store';
import { generateActivityUid } from '~/project-management/utils/pm-helper.utils';

const props = defineProps({
  activity: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['close', 'save']);

const $t = inject('$t');

const { createSurrogateTask } = useEvents();
const project_management_store = useProjectManagementStore();
const { $g, active_schedule, flags, is_fullscreen } = storeToRefs(project_management_store);
const { modify_config, change_activity_id, handle_subtask_addition, set_active_task_uid } = project_management_store;

const state = reactive({
  data: [],
  form_data: {
    add_rows_bottom: 10,
  },
  reload_count: 0,
  is_saving: false,
  existing_ids: [],
  hands_on_table_instance: null,
  invalid_data: {
    is_table_empty: false,
    is_data_missing: false,
    has_duplicate_ids: false,
    project_table_empty: false,
    predecessors: [],
  },
});

const formatter = $g.value.ext.formatters.durationFormatter({
  enter: 'day',
  store: 'day',
  format: 'day',
  short: true,
  hoursPerWeek: 56,
  labels: {
    day: {
      full: 'day',
      plural: 'days',
      short: 'd',
    },
    week: {
      full: 'week',
      plural: 'weeks',
      short: 'w',
    },
    month: {
      full: 'month',
      plural: 'months',
      short: 'm',
    },
    year: {
      full: 'year',
      plural: 'years',
      short: 'y',
    },
  },
});

const linksFormatter = $g.value.ext.formatters.linkFormatter({ durationFormatter: formatter });

const customLinkFormatter = {
  format(link) {
    let formatted_value = `${link.source}`;
    if (link.type !== 'FS' || (link.lag && link.lag !== 0))
      formatted_value += ` ${link.type}`;
    if (link.lag && link.lag !== 0)
      formatted_value += `${link.lag > 0 ? '+' : ''}${link.lag}${$t('days')}`;
    return formatted_value;
  },
  parse(formatted_value) {
    formatted_value = formatted_value.trim();

    const pattern = /^(\S*)(?:\s.*)?$/;
    let result = formatted_value.replace(pattern, (match, p1) => {
      const new_value = $g.value.getTask(p1);
      return formatted_value.replace(p1, $g.value.getWBSCode(new_value));
    });
    result = result.replaceAll(' ', '');
    const link = linksFormatter.parse(result);
    return link;
  },
};

const hot_columns = computed(() => {
  const columns = [
    {
      data: 'id',
      config: {
        required: true,
      },
      validator: 'default-validator',
      header: $t('ID'),
    },
    {
      data: 'text',
      config: {
        required: true,
      },
      validator: 'default-validator',
      header: $t('Activity'),
    },
    {
      data: 'start_date',
      type: 'date',
      config: {
        required: true,
      },
      validator: 'default-validator',
      header: $t('Planned Start'),
    },
    {
      data: 'duration',
      type: 'numeric',
      config: {
        required: true,
        field_type: 'numeric',
      },
      validator: 'default-validator',
      header: $t('Duration'),
    },
    {
      data: 'end_date',
      readOnly: true,
      renderer: endDateRenderer,
      header: $t('Planned Finish'),
    },
    ...(active_schedule.value.has_activity_weightages
      ? [
          {
            data: 'weight',
            type: 'numeric',
            header: $t('Weight'),
          },
        ]
      : []),
    {
      data: 'predecessors',
      type: 'text',
      header: $t('Predecessors'),
    },
  ];

  return columns;
});

const hot_table_height = computed(() => {
  let calculated_height = (state.data.length + 1) * 36 + 1;
  if (calculated_height > 500)
    calculated_height = 500;
  return `${calculated_height}px`;
});

const activity_display_text = computed(() => {
  const activity = $g.value.getTask(props.activity.id);
  return `${activity.text} (${activity.id})`;
});

const children_activities_ids = computed(() => {
  return $g.value.getChildren(props.activity.id);
});

const children_activities = computed(() => {
  return children_activities_ids.value.map(child => $g.value.getTask(child)).filter(activity => activity.type !== $g.value.config.types.surrogate);
});

function addRowsAtBottom() {
  for (let i = 0; i < state.form_data.add_rows_bottom; i++) {
    state.hands_on_table_instance.alter('insert_row_below');
  }
}

function endDateRenderer(_instance, td, row, _col, _prop, _value, _cellProperties) {
  td.classList.add('read-only-cell');
  if (state.data[row]?.start_date && state.data[row]?.duration) {
    td.textContent = dayjs($g.value.calculateEndDate(dayjs(state.data[row]?.start_date).startOf('day').toDate(), state.data[row]?.duration)).subtract(1, 'day').format('MMM DD, YYYY');
    return td;
  }
  td.textContent = '';
  return td;
}

function checkEmptyActivity(activity) {
  return Object.values(activity).every((value) => {
    return value === null || value === '' || value === undefined || value?.length === 0;
  });
}

function validatePredecessors(ids) {
  state.data.forEach((activity) => {
    if (activity.predecessors) {
      const sources = activity.predecessors?.split?.(',');
      sources?.forEach?.((value) => {
        const id = value.trim().split(' ')[0];

        const does_exist_in_gantt = $g.value.isTaskExists(id);
        if (!ids.includes(id) && !does_exist_in_gantt) {
          state.invalid_data.predecessors.push(id);
        }
        else if (!ids.includes(id) && does_exist_in_gantt) {
          const parent_id = $g.value.getParent(id);
          const parent_task = $g.value.getTask(parent_id);
          if (parent_task?.id === props.activity.id) {
            state.invalid_data.predecessors.push(id);
          }
        }
      });
    }
  });
  state.invalid_data.predecessors = uniq(state.invalid_data.predecessors);
}

function onSave() {
  state.invalid_data.is_table_empty = false;
  state.invalid_data.is_data_missing = false;
  state.invalid_data.has_duplicate_ids = false;
  state.invalid_data.project_table_empty = false;
  state.invalid_data.predecessors = [];
  state.is_saving = true;

  // Remove rows that are empty
  const indices_to_be_removed = [];
  state.data.forEach((activity, index) => {
    const copied_activity = {
      id: activity.id,
      text: activity.text,
      start_date: activity.start_date,
      duration: activity.duration,
      weight: activity.weight,
      predecessors: activity.predecessors,
    };
    if (!active_schedule.value.has_activity_weightages)
      delete copied_activity.weight;
    const is_empty_task = checkEmptyActivity(copied_activity);
    if (is_empty_task)
      indices_to_be_removed.push(index - indices_to_be_removed.length);
  });

  indices_to_be_removed.forEach((index) => {
    if (state.data.length === 1 && children_activities.value.length === 0) {
      state.invalid_data.is_table_empty = true;
      return;
    }
    state.hands_on_table_instance.alter('remove_row', index);
  });
  // If the table is empty and there are no existing children, throw error
  if (state.data.length === 1 && state.invalid_data.is_table_empty) {
    state.is_saving = false;
    return;
  }
  // If the table is empty and there are existing children, delete all the children
  else if (state.data.length === 0) {
    if (props.activity.is_root) {
      state.is_saving = false;
      state.invalid_data.project_table_empty = true;
      return;
    }
    setTimeout(() => {
      const task = $g.value.getTask(props.activity.id);
      task.type = $g.value.config.types.task;
      task._original_type = $g.value.config.types.task;
      children_activities_ids.value.forEach((child) => {
        $g.value.deleteTask(child);
      });
      $g.value.updateTask(props.activity.id);
      $g.value.autoSchedule();
      flags.value.activities_updated_count++;
      emit('close');
    }, 1000);
  }
  else {
    state.invalid_data.is_table_empty = false;
    state.invalid_data.project_table_empty = false;
  }

  // If there are duplicate IDs within the table, throw error
  const ids = state.data.map(activity => activity.id);
  if (ids.length !== new Set(ids).size) {
    state.is_saving = false;
    state.invalid_data.has_duplicate_ids = true;
    state.invalid_data.is_data_missing = false;
    state.invalid_data.is_table_empty = false;
    return;
  }

  validatePredecessors(ids);
  if (state.invalid_data.predecessors.length) {
    state.is_saving = false;
    return;
  }

  // If the above manual conditions are met, validate the cells using hot for missing data
  state.hands_on_table_instance.validateCells((is_valid) => {
    state.invalid_data.is_data_missing = !is_valid;
  });

  // The above validateCells may take time for the cells to be validated, hence the setTimeout
  setTimeout(() => {
    // If HOT validation fails, throw error
    if (state.invalid_data.is_data_missing) {
      state.is_saving = false;
      return;
    }

    // If the same ID is present anywhere in the Gantt (outside the table), throw error
    state.existing_ids = [];
    state.data.forEach((child) => {
      if (children_activities_ids.value.includes(child.id)) {
        return;
      }
      if ($g.value.isTaskExists(child.id)) {
        state.existing_ids.push(child.id);
        state.existing_ids = uniq(state.existing_ids);
      }
    });
    if (state.existing_ids.length) {
      state.is_saving = false;
      return;
    }

    // Data processing
    state.data = state.data.map((activity) => {
      return {
        ...activity,
        id: activity.id?.trim?.()?.replaceAll?.(' ', ''),
        start_date: dayjs(activity.start_date).startOf('day').toDate(),
      };
    });

    // If no subtask, add all tasks
    if (children_activities.value.length === 0) {
      state.data.forEach((child) => {
        const new_task_object = {
          ...child,
          readonly: false,
          status: 'Not started',
          parent: props.activity.id,
          uid: generateActivityUid(),
          is_backend_save_pending: true,
          type: $g.value.config.types.task,
        };
        delete new_task_object.predecessors;
        $g.value.addTask(new_task_object);
        active_schedule.value.activities[new_task_object.uid] = new_task_object;
      });
      createSurrogateTask(props.activity.id);
      const parent_task = $g.value.getTask(props.activity.id);
      handle_subtask_addition(parent_task);
    }
    // If subtasks are present, check if the uid is present in the data. If yes, update the existing task. Else create a new task.
    else {
      // Remove the tasks that are not present in the table
      children_activities.value.forEach((child) => {
        if (!state.data.find(activity => activity.uid === child.uid)) {
          $g.value.deleteTask(child.id);
        }
      });

      // Update the existing tasks and add new tasks
      state.data.forEach((child, index) => {
        if (child.uid) {
          const existing_task = children_activities.value.find(activity => activity.uid === child.uid);
          if (existing_task.id !== child.id)
            change_activity_id(existing_task.id, child.id);
          existing_task.text = child.text;
          existing_task.start_date = child.start_date;
          existing_task.duration = child.duration;
          existing_task.end_date = $g.value.calculateEndDate(child.start_date, child.duration);
          existing_task.weight = child.weight;
          $g.value.updateTask(existing_task.id);
        }
        else {
          const new_task_object = {
            ...child,
            readonly: false,
            status: 'Not started',
            parent: props.activity.id,
            uid: generateActivityUid(),
            is_backend_save_pending: true,
            type: $g.value.config.types.task,
          };
          delete new_task_object.predecessors;
          $g.value.addTask(new_task_object, props.activity.id, index);
          active_schedule.value.activities[new_task_object.uid] = new_task_object;
        }
      });
    }

    // Parse the predecessors using the Gantt's custom link formatter
    const links = [];
    state.data.forEach((activity) => {
      if (activity.predecessors) {
        const sources = activity.predecessors?.split?.(',');
        sources?.forEach?.((value) => {
          const link = customLinkFormatter.parse(value);
          link.target = activity.id;
          if ($g.value.isTaskExists(link.source) || ids.includes(link.source)) {
            links.push(link);
          }
        });
      }
    });
    // Removing existing links that are not present in the table
    const existing_links = $g.value.getLinks().filter(link => children_activities_ids.value.includes(link.target));
    existing_links.forEach((link) => {
      if (!links.find(l => l.source === link.source && l.target === link.target)) {
        $g.value.deleteLink(link.id);
      }
    });
    // If there is an existing link, ignore it. Else, add the link
    links.forEach((link) => {
      if (!existing_links.find(l => l.source === link.source && l.target === link.target)) {
        $g.value.addLink(link);
      }
      else {
        const link_to_update = existing_links.find(l => l.source === link.source && l.target === link.target);
        link_to_update.type = link.type;
        link_to_update.lag = link.lag;
        $g.value.updateLink(link_to_update.id);
      }
    });

    // Open the parent, change WBS levels and change task types
    $g.value.getTask(props.activity.id).$open = true;
    modify_config({ key: 'wbs_level', value: Number.MAX_SAFE_INTEGER });
    modify_config({ key: 'wbs_level_max', value: Number.MAX_SAFE_INTEGER });
    if (!props.activity.is_root) {
      $g.value.getTask(props.activity.id).type = $g.value.config.types.project;
      $g.value.getTask(props.activity.id)._original_type = $g.value.config.types.wbs;
    }
    $g.value.updateTask(props.activity.id);
    $g.value.autoSchedule();
    flags.value.activities_updated_count++;
    emit('close');
  }, 500);
}

onMounted(() => {
  state.is_loading = true;
  if (children_activities.value.length) {
    state.data = children_activities.value.map((activity) => {
      const links = activity.$target;
      let predecessors = [];
      for (const link_item of links) {
        const link = $g.value.getLink(link_item);
        predecessors.push(customLinkFormatter.format(link));
      }
      predecessors = predecessors.join(', ');
      return {
        id: activity.id,
        uid: activity.uid,
        text: activity.text,
        start_date: dayjs(activity.start_date).format('DD/MM/YYYY'),
        duration: activity.duration,
        weight: activity.weight,
        predecessors,
      };
    });
  }
  const empty_item = { id: null, uid: null, text: null, start_date: null, duration: null, weight: null, predecessors: '' };
  state.data.push(cloneDeep(empty_item));
  setTimeout(() => {
    state.is_loading = false;
  }, 1000);

  document.addEventListener('Enter', (event) => {
    const el = document.querySelector('.pika-single');
    if (el) {
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  });
});

watch(() => state.reload_count, () => {
  state.is_loading = true;
  setTimeout(() => {
    state.is_loading = false;
  }, 1000);
});

watch(() => state.data.length, () => {
  let calculated_height = (state.data.length + 1) * 36 + 1;
  if (calculated_height > 500)
    calculated_height = 500;

  state.hands_on_table_instance?.updateSettings({
    height: `${calculated_height}px`,
  });
}, { immediate: true });
</script>

<template>
  <HawkModalContainer
    :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body', escToClose: false }"
    content_class="w-[80vw]"
  >
    <Vueform
      v-model="state.form_data"
      sync
      size="sm"
      :display-errors="false"
      :display-messages="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div v-if="children_activities.length">
              Manage subtasks of
              <span
                v-tippy="activity_display_text.length > 60 ? activity_display_text : ''"
                class="text-primary-700 cursor-pointer"
                @click="set_active_task_uid(props.activity.uid)"
              >
                {{ $filters.truncate(activity_display_text, 60) }}
              </span>
            </div>
            <div v-else>
              Add subtasks for
              <span
                v-tippy="activity_display_text.length > 60 ? activity_display_text : ''"
                class="text-primary-700 cursor-pointer"
                @click="set_active_task_uid(props.activity.uid)"
              >
                {{ $filters.truncate(activity_display_text, 60) }}
              </span>
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <HawkLoader v-if="state.is_loading" />
          <div v-show="!state.is_loading">
            <div
              v-if="
                props.activity.type === $g.config.types.task
                  || props.activity.$target.length
              "
              class="mb-6 border border-gray-300 bg-gray-25 flex items-center gap-3 rounded-lg p-4 w-fit"
            >
              <IconHawkInfoCircle class="text-gray-600" />
              <div class="text-sm font-normal text-gray-700 w-fit">
                <template v-if="props.activity.type === $g.config.types.task">
                  The activity <span class="font-semibold">{{ activity_display_text }}</span> will be converted to type WBS and the data in the below table will be its children.
                </template>
                <template v-if="props.activity.$target.length">
                  <span class="font-semibold">{{ activity_display_text }}</span> is a target of a few link(s). Therefore, the planned start dates mentioned in this table may be ignored.
                </template>
              </div>
            </div>
            <HawkHandsontable
              :key="state.reload_count"
              :hot-settings="{
                datePickerConfig: {
                  disableDayFn(date) {
                    return !$g?.isWorkTime?.(date);
                  },
                },
              }"
              :row-sorting="false"
              :data="state.data"
              :columns="hot_columns"
              :col-headers="hot_columns.map(column => column.header)"
              :columns-menu="{ items: {} }"
              :height="hot_table_height"
              @ready="$event => state.hands_on_table_instance = $event"
            />
            <div class="flex items-center gap-1 mt-3 text-sm font-normal">
              <HawkButton
                type="outlined"
                color="gray"
                @click="addRowsAtBottom"
              >
                Add
              </HawkButton>
              <TextElement
                name="add_rows_bottom"
                class="w-20 ml-1"
                :presets="['underline']"
                input-type="number"
                :rules="['required', 'numeric', 'min:0']"
              />
              more rows at the bottom.
            </div>
            <div v-if="state.existing_ids.length" class="text-sm text-error-600 mt-3">
              The ID(s): <span v-for="(id, index) in state.existing_ids" :key="id" class="font-semibold">
                {{ id }}<template v-if="index !== state.existing_ids.length - 1">
                  ,
                </template>
              </span> is/are present outside the currently selected task.
              Please enter a different ID.
            </div>
            <div v-if="state.invalid_data.is_table_empty" class="text-sm text-error-600 mt-3">
              The table cannot be empty. Please fill in the details.
            </div>
            <div v-else-if="state.invalid_data.is_data_missing" class="text-sm text-error-600 mt-3">
              Please fill valid values for the cells in red color to be able to save.
            </div>
            <div v-else-if="state.invalid_data.has_duplicate_ids" class="text-sm text-error-600 mt-3">
              The table cannot have duplicate IDs. Please enter a different ID.
            </div>
            <div v-else-if="state.invalid_data.predecessors.length" class="text-sm text-error-600 mt-3">
              Invalid predecessor(s) found. The ID(s) - <span v-for="(id, index) in state.invalid_data.predecessors" :key="id" class="font-semibold">
                {{ id }}<template v-if="index !== state.invalid_data.predecessors.length - 1">
                  ,
                </template>
              </span> do not exist
            </div>
            <div v-else-if="state.invalid_data.project_table_empty" class="text-sm text-error-600 mt-3">
              The project must have at least one task. Please add a task to the project.
            </div>
          </div>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <ButtonElement
                class="mr-4"
                :secondary="true"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </ButtonElement>
              <ButtonElement
                name="save"
                :loading="state.is_saving"
                submits
              >
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>

<style>
.changeType {
  @apply hidden;
}
</style>
