import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import minMax from 'dayjs/plugin/minMax';
import { reduce, uniq, valuesIn } from 'lodash-es';
import { storeToRefs } from 'pinia';
import slugify from 'slugify';

import { computed, inject, ref } from 'vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import useAbortController from '~/common/composables/abort-controller.js';
import { useCommonStore } from '~/common/stores/common.store.js';
import { csvInjectionProtector } from '~/common/utils/common.utils.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

dayjs.extend(minMax);
dayjs.extend(isSameOrBefore);

const color_map = {
  default: 'FFFFFF',
  font: '434343',
  border: 'b7b7b7',
  critical: {
    yes: 'f4cccc',
    no: 'd9ead3',
  },
  date: {
    less: 'f4cccc', // pink
    equal: 'FFFFFF', // white
    more: 'd9ead3', // green
  },
  status: {
    'not started': 'd9d9d9',
    'on track': 'fce5cd',
    'delayed': 'f4cccc',
    'ahead': 'c9daf8',
    'completed': 'd9ead3',
  },
  status_scale: {
    min: 'd9ead3',
    max: '38761d',
  },
  parent_activity_style: {
    font_color: '000000',
    bg_color: 'd9d9d9',
  },
};
export function useExportProgressReport() {
  const $t = inject('$t');
  const $services = inject('$services');
  const common_store = useCommonStore();
  const project_management_store = useProjectManagementStore();
  const auth_store = useAuthStore();
  const controllers = useAbortController();

  const { $g } = storeToRefs(project_management_store);

  const is_report_exporting = ref(false);

  const CELL_TITLES = ['WBS', 'Activity', 'Planned Start', 'Planned Finish', 'Planned Duration', 'Actual Start', 'Actual Finish', 'Actual Duration', '% Progress', 'Scheduled progress', 'Status', 'Critical'];

  const fixed_columns = [
    { key: 'wbs' },
    { key: 'activity', width: 20 },
    { key: 'planned_start', width: 15 },
    { key: 'planned_finish', width: 15 },
    { key: 'planned_duration', width: 15 },
    { key: 'actual_start', width: 15 },
    { key: 'actual_finish', width: 15 },
    { key: 'actual_duration', width: 15 },
    { key: 'progress' },
    { key: 'schedule_progress' },
    { key: 'status', width: 15 },
    { key: 'critical' },
  ];

  const active_schedule = computed(() => project_management_store.active_schedule);
  const schedule_name = computed(() => active_schedule.value?.name);

  function setExporting(value) {
    is_report_exporting.value = value;
  }

  function cancelExportingScheduleReports() {
    controllers.abort('export_pm_report');
    setExporting(false);
  }

  function setupSheetHeaderInfo(worksheet) {
    worksheet.mergeCells('B2:J2');
    worksheet.getCell('B2').value = 'Project Management Progress Report';

    const second_rows = worksheet.getRow(2);
    second_rows.height = 42;
    worksheet.getCell('B2').style = {
      font: {
        size: 30,
        bold: true,
        color: { argb: '0b5394' },
      },
      alignment: {
        vertical: 'middle',
        wrapText: true,
      },
    };
    second_rows.eachCell((cell) => {
      cell.border = {
        bottom: { style: 'thin', color: { argb: '0b5394' } },
      };
    });
    const header_cells = {
      B4: {
        merge_with: 'C4',
        value: 'Organization',
        style: true,
      },
      D4: {
        merge_with: 'F4',
        value: csvInjectionProtector(auth_store.current_organization?.name) || '-',
        style: false,
      },
      G4: {
        merge_with: 'H4',
        value: 'Asset',
        style: true,
      },
      I4: {
        merge_with: 'J4',
        value: csvInjectionProtector(common_store?.active_asset?.name) || '-',
        style: false,
      },
      B5: {
        merge_with: 'C5',
        value: 'Schedule',
        style: true,
      },
      D5: {
        merge_with: 'F5',
        value: csvInjectionProtector(schedule_name.value) || '-',
        style: false,
      },
      G5: {
        merge_with: 'H5',
        value: 'Date',
        style: true,
      },
      I5: {
        merge_with: 'J5',
        value: dayjs().format('MMM D, YYYY'),
        style: false,
      },
    };

    for (const [cell, property] of Object.entries(header_cells)) {
      worksheet.mergeCells(`${cell}:${property.merge_with}`);
      const first_cell = worksheet.getCell(cell);
      const cell_border = {
        bottom: { style: 'thin', color: { argb: 'd9d9d9' } },
      };
      const second_cell = worksheet.getCell(property.merge_with);
      second_cell.border = cell_border;
      first_cell.value = property.value;
      if (property.style) {
        first_cell.style = {
          font: {
            bold: true,
            color: { argb: '666666' },
          },
          border: cell_border,
        };
      }
    }
  }
  function setupSheetRowTitles(worksheet, dates = []) {
    const starting_row = worksheet.getRow(8); // Add data from 8th row
    starting_row.height = 28;
    starting_row.values = [...CELL_TITLES, ...(dates.map(date => dayjs(date).format('DD-MMM-YY')))];
    starting_row.eachCell((cell, colNumber) => {
      cell.style = {
        font: {
          size: 9,
          color: { argb: '000000' },
          bold: true,
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true,
        },
      };
      if (colNumber <= CELL_TITLES.length) {
        cell.style.fill = createCellFill({ color: 'efefef' });
      }
      else {
        cell.style.numFmt = 'dd-mmm-yy';
        cell.style.fill = createCellFill({ color: 'bdd7ee' });
      }
    });
  }
  function createCellFill(options = {}) {
    return {
      type: options?.type || 'pattern',
      pattern: options?.pattern || 'solid',
      fgColor: { argb: options?.color || color_map.default },
    };
  }
  function createCellBorder(options) {
    const { borders, style, color } = options;
    const cell_borders = {};
    borders.forEach((side) => {
      cell_borders[side] = { style, color: { argb: color } };
    });
    return cell_borders;
  }

  function formattedDate(date) {
    if (!date)
      return '-';
    return dayjs(date).format('MMM D, YYYY');
  }

  function getMinMaxDate(activities = [], history = false) {
    let flatten_dates = [];
    if (history)
      flatten_dates = activities;
    else
      flatten_dates = activities.flatMap(data => [data.planned_start, data.planned_finish, data.actual_start, data.actual_finish]);

    const filtered_dates = reduce(flatten_dates, (result, date) => {
      if (date) {
        const formatted_date = dayjs(date).format('DD-MMM-YY');
        result.push(dayjs(formatted_date));
      }
      return result;
    }, []);
    return {
      min_date: dayjs.min(filtered_dates),
      max_date: dayjs.max(filtered_dates),
    };
  }

  function getDatesBetween(min_date, max_date) {
    const dates = [];
    let current_date = min_date || dayjs(min_date);
    const end_date = max_date || dayjs(max_date);
    while (current_date.isSameOrBefore(end_date)) {
      dates.push(current_date.format('DD-MMM-YY'));
      current_date = current_date.add(1, 'day');
    }
    return dates;
  }

  function createKeyValueForDate(history, between_dates = []) {
    const history_dates_map = reduce(history?.actual_progress_history || [], (dates, item) => {
      const [date, value] = item;
      const date_key = dayjs(date).format('DD-MMM-YY');
      dates[date_key] = value ? Math.round(value * 100) : '';
      return dates;
    }, {});
    return between_dates.reduce((result, date) => {
      result[date] = history_dates_map[date] || '';
      return result;
    }, {});
  }

  async function fetchAllProgressHistory() {
    try {
      const response = await $services.project_management.post({
        attribute: `schedules/${active_schedule.value.uid}/progress-history/bulk`,
        body: {},
      });
      if (response?.data?.data)
        return response.data.data;

      return {};
    }
    catch (er) {
      logger.error(er);
      setExporting('false');
    }
  }

  function createRowObject(task) {
    const is_task_critical = task.progress !== 1 && ($g.value.isCriticalTask(task) || (active_schedule.value.deadline ? dayjs(task.end_date).isAfter(active_schedule.value.deadline) : false));
    let planned_finish = task.planned_finish;
    let actual_finish = task.actual_finish;
    if (task?.planned_finish && !dayjs(task.planned_start).isSame(dayjs(task.planned_finish), 'day'))
      planned_finish = dayjs(task.planned_finish).subtract(1, 'day');
    if (task?.actual_finish && !dayjs(task.actual_start).isSame(dayjs(task.actual_finish), 'day'))
      actual_finish = dayjs(task.actual_finish).subtract(1, 'day');
    return {
      wbs: csvInjectionProtector(task.task_index),
      activity: csvInjectionProtector(task.name),
      planned_start: formattedDate(task?.planned_start),
      planned_finish: formattedDate(planned_finish),
      planned_duration: task?.planned_duration || '',
      actual_start: formattedDate(task?.actual_start),
      actual_finish: formattedDate(actual_finish),
      actual_duration: task?.actual_duration || '',
      progress: Math.round(task.progress * 100),
      schedule_progress: Math.round(task?.percent_schedule_complete * 100),
      status: task.progress_status ? $t(csvInjectionProtector(task.progress_status)) : '-',
      critical: is_task_critical ? $t('Yes') : $t('No'),
    };
  }

  function expectedDateColor(expected_rate, current_value) {
    if (!current_value)
      return color_map.default;
    const expected = Math.round(expected_rate * 100);
    const current = current_value;
    if (expected === current)
      return color_map.date.equal;
    else if (expected < current)
      return color_map.date.less;
    else if (expected > current)
      return color_map.date.more;
  }

  function getHistoryDates(progress_history) {
    const dates = [];
    valuesIn(progress_history).forEach((progress) => {
      for (const [date] of (progress.actual_progress_history || []))
        dates.push(date);
    });
    return getMinMaxDate(uniq(dates), true);
  }

  async function exportScheduleReports() {
    try {
      controllers.add('export_pm_report');
      const ExcelJS = await import('exceljs');
      const { saveAs } = await import('file-saver');
      const progress_history = await fetchAllProgressHistory();
      const progress_history_dates = getHistoryDates(progress_history);
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('PM report', {
        views: [{ state: 'frozen', xSplit: fixed_columns.length, showGridLines: false }],
      });

      const { min_date, max_date } = getMinMaxDate(valuesIn(active_schedule.value.activities));
      const dates_between = getDatesBetween(dayjs.min([min_date, progress_history_dates.min_date]), dayjs.max([max_date, progress_history_dates.max_date]));

      const date_columns = dates_between?.map(date => ({ key: date }));

      worksheet.properties.outlineProperties = { summaryBelow: false };
      worksheet.columns = [...fixed_columns, ...date_columns];

      setupSheetHeaderInfo(worksheet);
      setupSheetRowTitles(worksheet, dates_between);

      $g.value.eachTask((task) => {
        const progress_history_obj = progress_history[task.uid] || {};
        const dates_values_map = createKeyValueForDate(progress_history_obj, dates_between);
        const row_object = createRowObject({
          ...task,
          task_index: $g.value.getWBSCode(task),
          progress_status: progress_history_obj?.status || '-',
        });
        const row = worksheet.addRow({ ...row_object, ...dates_values_map });

        row.outlineLevel = task.$level;

        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          cell.name = fixed_columns[colNumber - 1]?.key;
          cell.style = {
            font: { color: { argb: color_map.font } },
            alignment: { horizontal: ['wbs', 'activity'].includes(cell.name) ? 'left' : 'center' },
            border: createCellBorder({ borders: ['bottom'], style: 'thin', color: color_map.border }),
          };

          if (
            ['wbs', 'activity'].includes(cell.name)
            && ![$g.value.config.types.task, $g.value.config.types.milestone].includes(task.type)
          ) {
            cell.style.font = {
              color: { argb: color_map.parent_activity_style.font_color },
              size: 11,
              bold: true,
            };
            cell.style.fill = createCellFill({ color: color_map.parent_activity_style.bg_color });
          }

          if (colNumber > fixed_columns.length) {
            cell.style.border = createCellBorder({ borders: ['top', 'bottom', 'left', 'right'], style: 'dotted', color: color_map.border });
            cell.style.fill = createCellFill({ color: expectedDateColor(task?.percent_schedule_complete, cell.value) });
          }

          if (cell.name === 'critical') {
            const is_task_critical = task.progress !== 1 && ($g.value.isCriticalTask(task) || (active_schedule.value.deadline ? dayjs(task.end_date).isAfter(active_schedule.value.deadline) : false));
            cell.style.fill = createCellFill({ color: is_task_critical ? color_map.critical.yes : color_map.critical.no });
          }

          if (cell.name === 'status') {
            const status_name = cell.value?.toLocaleLowerCase();
            cell.style.fill = createCellFill({ color: color_map.status[status_name] });
          }
        });
        row.commit();
      });

      const color_scale_rule = [{
        type: 'colorScale',
        priority: 0,
        cfvo: [
          { type: 'num', value: 0 },
          { type: 'num', value: 100 },
        ],
        color: [{ argb: color_map.status_scale.min }, { argb: color_map.status_scale.max }],
      }];
      worksheet.addConditionalFormatting({
        ref: `I9:I${worksheet.rowCount}`,
        rules: color_scale_rule,
      });

      if (is_report_exporting.value) {
        const slugified_name = slugify(schedule_name.value, { lower: true, strict: true });
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), `${slugified_name}-progress-report.xlsx`);
        setTimeout(() => {
          setExporting(false);
        }, 3000);
      }
    }
    catch (er) {
      logger.error(er);
      setExporting(false);
    }
  }

  return {
    is_report_exporting,
    setExporting,
    cancelExportingScheduleReports,
    exportScheduleReports,
  };
}
