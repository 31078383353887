<script setup>
const emit = defineEmits(['close']);

const $t = inject('$t');

// TODO: i18n
const shortcut_items = [
  {
    group_label: $t('Row shortcuts'),
    items: [
      { label: $t('Activate editor'), keys: ['enter'] },
      { label: 'Toggle branch', keys: ['Space bar'] },
      { label: $t('Expand branch'), keys: ['shift', 'down arrow'] },
      { label: $t('Collapse branch'), keys: ['shift', 'up arrow'] },
      { label: $t('Indent'), keys: ['ctrl', 'shift', 'right arrow'] },
      { label: $t('Outdent'), keys: ['ctrl', 'shift', 'left arrow'] },
      { label: 'Move a task up within a hierarchy', keys: ['ctrl', 'shift', 'up arrow'] },
      { label: 'Move a task down within a hierarchy', keys: ['ctrl', 'shift', 'down arrow'] },
    ],
  },
  {
    group_label: $t('Navigation'),
    items: [
      { label: $t('General'), keys: ['left arrow', 'right arrow', 'up arrow', 'down arrow'] },
      { label: $t('Next cell'), keys: ['Tab'] },
      { label: $t('Previous cell'), keys: ['shift', 'Tab'] },
    ],
  },
];
</script>

<template>
  <HawkModalContainer>
    <div class="col-span-12">
      <HawkModalHeader @close="emit('close')">
        <template #title>
          <div class="flex flex-col justify-start">
            {{ $t('Keyboard Shortcuts') }}
          </div>
        </template>
      </HawkModalHeader>
      <HawkModalContent>
        <div
          v-for="(shortcut_item, shortcut_item_idx) in shortcut_items"
          :key="shortcut_item_idx"
          class="pb-4"
          :class="{ 'mt-6': shortcut_item_idx > 0 }"
        >
          <div class="text-xs font-semibold text-gray-400">
            {{ shortcut_item.group_label }}
          </div>
          <div v-for="item in shortcut_item.items" :key="item">
            <div class="flex justify-between items-center h-12">
              <div class="text-sm font-normal text-gray-600">
                {{ item.label }}
              </div>
              <div>
                <span
                  v-for="(key, index) in item.keys"
                  :key="index"
                  class="text-xs font-semibold bg-gray-50 text-gray-600 py-1.5 px-1.5 border border-gray-300 ml-1 rounded-lg"
                >
                  <kbd v-if="key === 'enter'">
                    Enter
                    <IconHawkCornerDownLeft class="inline w-3.5 h-3.5 text-gray-600" />
                  </kbd>
                  <kbd v-else-if="key === 'shift'">
                    Shift
                    <IconHawkArrowBlockUp class="inline -mt-0.5 w-3.5 h-3.5 text-gray-600" />
                  </kbd>
                  <kbd v-else-if="key === 'ctrl'">
                    Ctrl ^
                  </kbd>
                  <IconHawkArrowRight
                    v-else-if="key === 'right arrow'"
                    class="inline w-3.5 h-3.5 text-gray-600"
                  />
                  <IconHawkArrowLeft
                    v-else-if="key === 'left arrow'"
                    class="inline w-3.5 h-3.5 text-gray-600"
                  />
                  <IconHawkArrowDown
                    v-else-if="key === 'down arrow'"
                    class="inline w-3.5 h-3.5 text-gray-600"
                  />
                  <IconHawkArrowUp
                    v-else-if="key === 'up arrow'"
                    class="inline w-3.5 h-3.5 text-gray-600"
                  />
                  <kbd v-else>{{ key }}</kbd>
                </span>
              </div>
            </div>
            <hr class="-mx-6 border-gray-100">
          </div>
        </div>
      </HawkModalContent>
    </div>
  </HawkModalContainer>
</template>
