import PmNewSchedule from '~/project-management/pages/pm-new-schedule.vue';
import PmScheduleDetails from '~/project-management/pages/pm-schedule-details.vue';
import PmSchedulesList from '~/project-management/pages/pm-schedules-list.vue';

const routes = [
  {
    path: '/:asset_id/project-management/',
    name: 'project-management',
    component: PmSchedulesList,
    meta: {
      title: 'Project Management',
    },
  },
  {
    path: '/:asset_id/project-management/new',
    name: 'new-schedule',
    component: PmNewSchedule,
    meta: {
      title: 'New schedule',
    },
  },
  {
    path: '/:asset_id/project-management/:schedule_uid',
    name: 'schedule-details',
    component: PmScheduleDetails,
    meta: {
      title: 'Schedule Details',
    },
  },
];
export default routes;
