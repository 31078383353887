<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { uniq } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { currencies } from '~/common/utils/constants.ts';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const props = defineProps({
  operation_type: {
    type: String,
    default: 'create',
  },
  field_name: {
    type: String,
    default: '',
  },
  field_type: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['close', 'save']);

const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const { active_schedule, is_fullscreen } = storeToRefs(project_management_store);
const { create_update_or_delete_custom_field } = project_management_store;

dayjs.extend(customParseFormat);

const state = reactive({
  field_data: {
    name: props.field_name,
    type: props.field_type,
  },
  is_loading: false,
});

const currency_items = computed(() => Object.values(currencies).map((c) => {
  return {
    ...c,
    label: c.name,
    value: c.code,
  };
}));
const custom_field_names = computed(() => Object.keys(active_schedule.value?.custom_fields || {}));
const fieldname_already_exists = computed(() => state.field_data.name !== props.field_name && custom_field_names.value.includes(state.field_data.name));
const prevent_save_create = computed(() => {
  if (fieldname_already_exists.value)
    return true; // prevents save if the name already exists
  if (!state.field_data.name)
    return true; // prevents save if the name is null or undefined
  if (
    state.field_data.name === props.field_name
    // && (props?.config?.currency?.code === state.field_data?.currency)
    // && state.field_data.value === props.field_value
  )
    return true; // prevents save if the name, currency and value are unchanged
  return !state.field_data.type; // prevents save if the type is null or undefined
  // return (state.field_data.type === 'money' && (!state.field_data.currency)); // prevents save if the currency is null or undefined
});
const prevent_save_update = computed(() => {
  if (state.field_data.name !== props.field_name && fieldname_already_exists.value)
    return true; // prevents save if the name has changed and the new name already exists
  if (!state.field_data.name)
    return true; // prevents save if the name is null or undefined
  return state.field_data.name === props.field_name; // prevents save if the name is unchanged
});
const is_save_button_disabled = computed(() => props.operation_type === 'update' ? prevent_save_update.value : prevent_save_create.value);

const type_items = [
  ['text', 'Text'],
  ['number', 'Number'],
  ['money', 'Money'],
  ['date', 'Date'],
].map(item => ({
  value: item[0],
  label: $t(item[1]),
}));

async function createCustomField() {
  await create_update_or_delete_custom_field({
    type: 'post',
    body: {
      field: state.field_data.name,
      type: state.field_data.type,
      ...(state.field_data.type === 'money'
        ? {
            config: {
              currency: {
                code: active_schedule.value.currency?.code ?? currency_items.value.find(currency_item => currency_item.symbol === active_schedule.value.currency?.symbol)?.value,
                symbol: active_schedule.value.currency?.symbol,
              },
            },
          }
        : {}),
    },
  });
}

async function updateCustomField() {
  if (
    state.field_data.name !== props.field_name
  ) {
    await create_update_or_delete_custom_field({
      type: 'patch',
      body: {
        field: props.field_name,
        new_name: state.field_data.name,
        ...(state.field_data.type === 'money'
          ? {
              config: {
                currency: {
                  code: active_schedule.value.currency?.code ?? currency_items.value.find(currency_item => currency_item.symbol === active_schedule.value.currency?.symbol)?.value,
                  symbol: active_schedule.value.currency?.symbol,
                },
              },
            }
          : {}),
      },
    });
  }
}

async function saveField() {
  if (
    state.is_loading
    || (props.operation_type === 'create' && prevent_save_create.value)
    || (props.operation_type === 'update' && prevent_save_update.value)
  ) {
    return;
  }
  try {
    state.is_loading = true;
    if (props.operation_type === 'create')
      await createCustomField();

    else if (props.operation_type === 'update')
      await updateCustomField();

    emit('close');
    emit('save', state.field_data.name, props.field_name);
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
  }
}
</script>

<template>
  <HawkModalContainer :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body' }">
    <Vueform
      v-model="state.field_data"
      sync
      size="sm"
      :columns="{
        default: { container: 12, label: 5, wrapper: 12 },
        sm: { container: 12, label: 5, wrapper: 12 },
        md: { container: 12, label: 5, wrapper: 12 },
      }"
      :display-errors="false"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div v-if="props.operation_type === 'create'" class="text-lg text-gray-900 font-semibold">
              {{ $t('New Field') }}
            </div>
            <div v-else class="text-lg text-gray-900 font-semibold">
              {{ $t('Update Field') }}
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent :is_scroll="false">
          <TextElement
            :default="props.operation_type === 'create' ? '' : props.field_name"
            name="name"
            :placeholder="$t('Enter field name')"
            :label="$t('Name')"
            class="w-full mb-6"
            rules="not_regex:/\./"
          />
          <SelectElement
            name="type"
            :disabled="props.operation_type === 'update'"
            :default="props.operation_type === 'create' ? '' : props.field_type"
            :items="type_items"
            :search="true"
            :native="false"
            :label="$t('Type')"
            :placeholder="$t('Select field type')"
            input-type="search"
            autocomplete="off"
            :can-clear="false"
            class="w-full mb-6"
          />
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex flex-row-reverse justify-start w-full">
              <ButtonElement
                name="save"
                submits
                :button-label="$t('Save')"
                :disabled="is_save_button_disabled"
                :loading="state.is_loading"
                @click="saveField"
              />
              <ButtonElement
                name="cancel"
                :button-label="$t('Cancel')"
                :secondary="true" class="mr-4"
                @click="emit('close')"
              />
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
