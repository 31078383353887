import AlertTriangle from '~icons/hawk/alert-triangle?raw';
import AttachmentOne from '~icons/hawk/attachment-one?raw';
import DragIcon from '~icons/hawk/drag-icon?raw';
import Fire from '~icons/hawk/fire?raw';
import InfoCircle from '~icons/hawk/info-circle?raw';
import MessageDotsCircle from '~icons/hawk/message-dots-circle?raw';
import Plus from '~icons/hawk/plus?raw';
import PmAutoSync from '~icons/hawk/pm-auto-sync?raw';
import PmCompleted from '~icons/hawk/pm-completed?raw';
import PmOverdue from '~icons/hawk/pm-overdue?raw';
import ShareFour from '~icons/hawk/share-four?raw';
import TableAdd from '~icons/hawk/table-add?raw';
import UnreadMessageDotsCircle from '~icons/hawk/unread-message-dots-circle?raw';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { isNil, isNumber } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { computed, inject, nextTick, watch } from 'vue';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import { changeIconDimensions } from '~/common/utils/common.utils';
import { getUserFullName, stringToNumber } from '~/common/utils/common.utils.js';
import PmExcelModal from '~/project-management/components/pm-excel-modal.vue';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

export function useColumnTemplates() {
  const $t = inject('$t');
  const $date = inject('$date');

  const IconPlus = changeIconDimensions(Plus, 14, 14);
  const IconDrag = changeIconDimensions(DragIcon, 12, 12);
  const IconShareFour = changeIconDimensions(ShareFour, 14, 14);
  const IconTableAdd = changeIconDimensions(TableAdd, 15, 15);
  const IconFire = changeIconDimensions(Fire, 16, 16);
  const IconPmCompleted = changeIconDimensions(PmCompleted, 16, 16);
  const IconPmAutoSync = changeIconDimensions(PmAutoSync, 16, 16);
  const IconPmOverdue = changeIconDimensions(PmOverdue, 16, 16);
  // const IconMeterMedium = changeIconDimensions(MeterMedium, 16, 16);
  const IconHawkInfoCircle = changeIconDimensions(InfoCircle, 16, 16).replace('currentColor', 'white');
  const IconAlertTriangle = changeIconDimensions(AlertTriangle, 16, 16).replace('currentColor', 'white');
  const IconAttachmentOne = changeIconDimensions(AttachmentOne, 16, 16);
  const IconMessageDotsCircle = changeIconDimensions(MessageDotsCircle, 16, 16);
  const IconUnreadMessageDotsCircle = changeIconDimensions(UnreadMessageDotsCircle, 16, 16);

  const route = useRoute();
  const auth_store = useAuthStore();
  const common_store = useCommonStore();
  const project_management_store = useProjectManagementStore();

  const { set_active_task_uid } = project_management_store;
  const { $g, active_schedule, active_view, flags, pm_attachments, is_schedule_editable, selected_task_id } = storeToRefs(project_management_store);

  const asset_id = route.params.asset_id;

  let member_items = common_store.scope_users(asset_id);
  const team_items = common_store.scope_teams(asset_id);
  member_items = member_items.filter(item => item.status !== 'deprovisioned');
  const members = member_items?.map((user) => {
    return {
      ...common_store.get_user(user.uid),
      name: getUserFullName(user),
    };
  });
  const teams = team_items?.map((team) => {
    return {
      ...common_store.get_team(team.uid),
      name: getUserFullName(team),
    };
  });

  const active_date_range = computed(() => active_view.value.data.active_date_range);

  watch(active_date_range, (value) => {
    if (!value?.from || !value?.to) {
      $g.value.render();
      return;
    }
    $g.value.render();
    $g.value.showDate(value.from);
  });

  const excel_modal = useModal({
    component: PmExcelModal,
    attrs: {
      onClose() {
        excel_modal.close();
      },
    },
  });

  window.gantt.$triggerExcelModal = triggerExcelModal;
  async function triggerExcelModal(task) {
    excel_modal.patchOptions({
      attrs: {
        activity: task,
      },
    });
    excel_modal.open();
    const active_cell = { type: 'taskCell', id: task.id, column: 'text' };
    await nextTick();
    $g.value.ext.inlineEditors.hide();
    $g.value.ext.keyboardNavigation.focus(active_cell);
  }

  window.gantt.$triggerActivityDetails = triggerActivityDetails;
  async function triggerActivityDetails(task) {
    set_active_task_uid(task.uid);
    const active_cell = { type: 'taskCell', id: task.id, column: 'text' };
    await nextTick();
    $g.value.ext.inlineEditors.hide();
    $g.value.ext.keyboardNavigation.focus(active_cell);
  }

  const generateWBSTemplate = (task) => {
    const wbs_code = $g.value.getWBSCode(task);

    const is_user_history_present = task.users_view_history?.find?.(user_view_history => user_view_history.user_uid === auth_store.logged_in_user_details.user_id);
    let are_unread_comments_present = false;
    if (is_user_history_present)
      are_unread_comments_present = is_user_history_present.unread_comments;
    else if (task.last_comment)
      are_unread_comments_present = true;

    let are_comments_present = false;
    if (!are_unread_comments_present && task.last_comment)
      are_comments_present = true;

    const wbs_code_of_attachments = pm_attachments.value.map(item => item.wbs);

    if (task.type === $g.value.config.types.surrogate)
      return `<span class="flex items-center show-on-row-hover pl-[60px]">${wbs_code}</span>`;

    return `<span class="flex items-center cursor-move">
              <span class="flex items-center mr-2 gap-x-1">
                ${is_schedule_editable.value
                    ? `<span class="text-gray-300 hover:text-gray-500 show-on-row-hover">${IconDrag}</span>`
                    : ''
                }
                <span class="flex items-center gap-x-1">
                  <span data-task-id="${task.id}" class="gantt-attachments text-gray-500 cursor-pointer hover:text-gray-700 ${!wbs_code_of_attachments?.includes?.(wbs_code) ? 'show-on-row-hover' : ''}">
                    ${IconAttachmentOne}
                  </span>
                  ${
                    are_comments_present
                      ? `<span data-task-id="${task.id}" class="gantt-comments text-gray-500 cursor-pointer hover:text-gray-700 hide-on-row-hover">
                          ${IconMessageDotsCircle}
                        </span>`
                      : ``
                  }
                  ${
                    are_unread_comments_present
                      ? `<span data-task-id="${task.id}" class="gantt-comments text-gray-500 cursor-pointer hover:text-gray-700 hide-on-row-hover">
                          ${IconUnreadMessageDotsCircle}
                        </span>`
                      : ``
                  }
                  <span data-task-id="${task.id}" class="gantt-comments text-gray-500 cursor-pointer hover:text-gray-700 ${are_comments_present || are_unread_comments_present ? 'visible-on-row-hover' : 'show-on-row-hover'}" >
                    ${are_unread_comments_present ? IconUnreadMessageDotsCircle : IconMessageDotsCircle}
                  </span>
                </span>
              </span>
              ${wbs_code}
            </span>`;
  };

  const generateNameTemplate = (task, property) => {
    if (task.type === $g.value.config.types.virtual)
      return task[property];

    const wbs_code = $g.value.getWBSCode(task);

    if (task.type === $g.value.config.types.surrogate) {
      return `
      <span class="flex">
        <span data-task-wbs="${wbs_code}" data-task-id="${task.id}" class="add-task flex items-center font-semibold cursor-pointer gap-x-1 text-primary-700 hover:underline">
          ${IconPlus} ${$t('Add Task')}
        </span>
        <span class="bg-gray-300 min-w-[1px] w-[1px] h-4 mx-2 mt-[6px]"></span>
        <span data-task-wbs="${wbs_code}" data-task-id="${task.id}" class="add-milestone flex items-center font-semibold cursor-pointer gap-x-1 text-primary-700 hover:underline">
          ${IconPlus} ${$t('Add Milestone')}
        </span>
      </span>
    `;
    }

    const span_classes = ['flex', 'items-center', 'h-[22px]', 'mt-[4px]', 'mb-[2px]', 'p-2'];
    if (is_schedule_editable.value)
      span_classes.push('editor-trigger', 'rounded', 'bg-white', 'border', 'border-white', 'hover:border-gray-300', 'cursor-text');

    // const is_task_overload = task.resources?.some?.(resource_uid => resources_overloaded.value[resource_uid]?.includes?.(task.uid));
    const is_task_critical = $g.value.isCriticalTask(task) || (active_schedule.value.deadline ? dayjs(task.end_date).isAfter(active_schedule.value.deadline) : false);

    // ${
    //   is_task_overload
    //   ? `<span class="text-error-500 ml-2 scale-x-[-1]" title="${$t('Resources overloaded')}">${IconMeterMedium}</span>`
    //   : ''
    // }
    return `
    <div class="flex items-center relative">
      <span class="${span_classes.join(' ')}">${task[property]}</span>
      ${
        (active_view.value.data.feature_visibility.overdue && task.progress < task.percent_schedule_complete)
          ? `<span
              class="grid-tippy inline-block ml-2"
              data-tippy-placement="bottom"
              data-tippy-theme="small-text"
              data-tippy-content="${$t('Overdue')}"
            >
              ${IconPmOverdue}
            </span>`
          : ''
      }
      ${
        (
          active_view.value.data.feature_visibility.critical_path
          && task.progress !== 1
          && is_task_critical
        )
          ? `<span
              class="grid-tippy inline-block ml-2"
              data-tippy-placement="bottom"
              data-tippy-theme="small-text"
              data-tippy-content="${$t('Critical')}"
            >
              ${IconFire}
            </span>`
          : ''
      }
      ${
        task.progress === 1
          ? `<span
              class="grid-tippy inline-block ml-2"
              data-tippy-placement="bottom"
              data-tippy-theme="small-text"
              data-tippy-content="${$t('Completed')}"
            >
              ${IconPmCompleted}
            </span>`
          : ''
      }
      ${
        task?.auto_progress_sync?.is_enabled
          ? `<span
            class="grid-tippy inline-block ml-2"
            data-tippy-placement="bottom"
            data-tippy-theme="small-text"
            data-tippy-content="${$t('The progress is configured to sync automatically')}"
            >
              ${IconPmAutoSync}
            </span>`
          : ''
      }
      ${
        is_schedule_editable.value
          ? `<span
              task-uid="${task.uid}"
              class="grid-tippy trigger-excel-modal p-0.75 show-on-row-hover cursor-pointer absolute right-6 -mr-[3px] text-gray-400 hover:text-gray-600 bg-gray-50 hover:bg-white border border-transparent hover:border-gray-300 rounded"
              data-tippy-placement="bottom"
              data-tippy-theme="small-text"
              data-tippy-content="Add/manage subtasks"
            >
              ${IconTableAdd}
            </span>`
          : ''
      }
      <span
        task-uid="${task.uid}"
        class="grid-tippy trigger-activity-details select-trigger p-0.75 show-on-row-hover cursor-pointer absolute right-0 -mr-[3px] text-gray-400 hover:text-gray-600 bg-gray-50 hover:bg-white border border-transparent hover:border-gray-300 rounded"
        data-tippy-placement="bottom"
        data-tippy-theme="small-text"
        data-tippy-content="${$t('View details')}"
      >
        ${IconShareFour}
      </span>
    </div>`;
  };

  const generateStatusTemplate = (task) => {
    if (task.type === $g.value.config.types.surrogate)
      return '';

    if (task.progress === 1)
      return $t('Completed');

    else if (!task.progress)
      return $t('Not started');

    else
      return $t('Started');
  };

  const generateResourceAppendTemplate = (all_resources) => {
    if (all_resources.length > 1) {
      return `
      <span class="flex items-center px-1 h-[24px] ml-1 mt-0.5 bg-gray-100 rounded-xl">
        +${all_resources.length - 1}
      </span>
    `;
    }
    return '';
  };

  const generateResourceTemplate = (task) => {
    if (!active_schedule.value.track_resources)
      return $t('Resources tracking is turned off');
    const all_resources = task.resources?.map?.((item) => {
      return active_schedule.value?.resources?.find?.(el => el.uid === item);
    });

    if (task.type === $g.value.config.types.surrogate || !all_resources?.length)
      return '';

    let first_assignee = all_resources[0];
    if (!first_assignee)
      return '';

    if (common_store.is_type_user(first_assignee.external_id)) {
      const found = members.find(member => member.uid === first_assignee.external_id);
      first_assignee = {
        ...found,
        avatar: found?.display_picture || null,
      };
    }
    else if (first_assignee.type === 'custom') {
      first_assignee = {
        name: first_assignee.name,
        avatar: 'team',
      };
    }
    else {
      const found = teams.find(team => team.uid === first_assignee.external_id);
      if (found) {
        first_assignee = {
          name: found.name,
          avatar: 'team',
        };
      }
      else {
        first_assignee = {
          name: 'Unknown',
          avatar: null,
        };
      }
    }

    let avatar = '';
    if (first_assignee.avatar === 'team') {
      avatar = `<span class="w-4 h-4 flex justify-center items-center bg-gray-300 rounded-full">${first_assignee.name.charAt(0)}</span>`;
    }
    else if (!first_assignee.avatar) {
      avatar = `<span class="inline-flex items-center justify-center leading-none rounded-full text-white p-1 w-4 h-4" style="background-color: ${stringToNumber(getUserFullName(first_assignee))};">
            ${first_assignee.name?.charAt(0).toUpperCase()}
          </span>`;
    }
    else {
      avatar = `<img src="${first_assignee.avatar}" alt="" class="object-cover w-4 h-4 rounded-full">`;
    }

    const append = generateResourceAppendTemplate(all_resources);

    return `
      <div class="flex items-center">
        <span class="flex items-center h-[24px] mt-0.5 bg-gray-100 rounded-xl pl-1">
          ${avatar}
          <span class="ml-1.5 mr-2 truncate w-auto text-xs text-gray-700 font-medium">
            ${first_assignee.name}
          </span>
        </span>
        ${append}
      </div>`;
  };

  const generateAbsoluteWorkRateTemplate = (task, name) => {
    if (task.type === $g.value.config.types.surrogate)
      return '';

    const num = Number(task?.[name]);
    if (isNil(task?.[name]) || Number.isNaN(num))
      return '';
    const fixed = Math.abs(num).toFixed(2);
    if (num >= 0)
      return `${fixed}`;
    return `<span class="text-red-600">${fixed}</span>`;
  };

  const generateDefaultTemplate = (task, name, type) => {
    if (task.type === $g.value.config.types.surrogate)
      return '';

    if (type === 'date' && task?.[name]) {
      if (
        (name === 'end_date' && !dayjs(task.start_date).isSame(dayjs(task.end_date), 'day'))
        || (name === 'planned_finish' && !dayjs(task.planned_start).isSame(dayjs(task.planned_finish), 'day'))
        || (name === 'bl_finish' && !dayjs(task.bl_start).isSame(dayjs(task.bl_finish), 'day'))
        || (name === 'actual_finish' && !dayjs(task.actual_start).isSame(dayjs(task.actual_finish), 'day'))
      ) {
        return $date(dayjs(task[name]).subtract(1, 'day'), 'DATE_MED');
      }
      return $date(task[name], 'DATE_MED');
    }

    return task?.[name] || '';
  };

  const generateCustomFieldTemplate = (task, name) => {
    if (task.type === $g.value.config.types.surrogate)
      return '';

    const custom_fields = active_schedule.value.custom_fields || {};
    const code_name = name.replace('custom_field_', '');
    if (custom_fields[code_name]?.type === 'money') {
      return task.custom_field_values?.[code_name]
        ? `${custom_fields[code_name]?.config.currency.symbol}${task.custom_field_values[code_name]}`
        : '';
    }
    else if (custom_fields[code_name]?.type === 'date') {
      return task.custom_field_values?.[code_name]
        ? $date(task.custom_field_values[code_name], 'DATE_MED')
        : '';
    }
    else {
      return DOMPurify.sanitize(task.custom_field_values?.[code_name] || '', { ALLOWED_TAGS: [] });
    }
  };

  const templateForFixedFields = (name, type) => {
    const formatter = $g.value.ext.formatters.durationFormatter({
      enter: 'day',
      store: 'day',
      format: 'auto',
    });
    const linksFormatter = $g.value.ext.formatters.linkFormatter({ durationFormatter: formatter });
    const customLinkFormatter = {
      format(link) {
        let formatted_value = `${link.source}`;
        if (link.type !== 'FS' || (link.lag && link.lag !== 0))
          formatted_value += ` ${link.type}`;
        if (link.lag && link.lag !== 0)
          formatted_value += `${link.lag > 0 ? '+' : ''}${link.lag}${$t('days')}`;
        return formatted_value;
      },
      parse(formattedValue) {
        formattedValue = formattedValue.trim();

        const pattern = /^(\S*)(?:\s.*)?$/;
        const result = formattedValue.replace(pattern, (match, p1) => {
          const new_value = $g.value.getTask(p1);
          return formattedValue.replace(p1, $g.value.getWBSCode(new_value));
        });
        const link = linksFormatter.parse(result);
        return link;
      },
    };

    switch (name) {
      case 'wbs':
        return task => generateWBSTemplate(task);

      case 'text':
        return task => generateNameTemplate(task, name);

      case 'status':
        return task => generateStatusTemplate(task);

      case 'resources':
        return task => generateResourceTemplate(task);

      case 'constraint_type':
        return task => $g.value.locale.labels[$g.value.getConstraintType(task)];

      case 'constraint_date':
        return (task) => {
          const constraint_types = $g.value.config.constraint_types;

          if (task.constraint_date && task.constraint_type !== constraint_types.ASAP && task.constraint_type !== constraint_types.ALAP)
            return $date(task.constraint_date, 'DATE_MED');

          return '';
        };

      case 'predecessors':
        return (task) => {
          if (task.type === $g.value.config.types.surrogate)
            return '';
          const links = task.$target;
          const labels = [];
          for (const link_item of links) {
            const link = $g.value.getLink(link_item);
            labels.push(customLinkFormatter.format(link));
          }
          return labels.join(', ');
        };

      case 'successors':
        return (task) => {
          if (task.type === $g.value.config.types.surrogate)
            return '';

          try {
            return task[name].join(', ');
          }
          catch {
            return task?.[name] || '';
          }
        };

      case 'work_rate':
        return (task) => {
          if (task.type === $g.value.config.types.surrogate)
            return '';
          return (task?.work_rate && !Number.isNaN(task.work_rate))
            ? `${task.work_rate}/day`
            : '';
        };

      case 'duration':
        return (task) => {
          if (task.type === $g.value.config.types.surrogate)
            return '';

          return `${Math.ceil(task?.duration || 0)}`;
        };

      case 'is_critical':
        return (task) => {
          if (task.type === $g.value.config.types.surrogate)
            return '';
          if (task.progress === 1)
            return $t('No');

          const is_task_critical = $g.value.isCriticalTask(task) || (active_schedule.value.deadline ? dayjs(task.end_date).isAfter(active_schedule.value.deadline) : false);
          return !flags.value.is_task_being_dragged && is_task_critical ? $t('Yes') : $t('No');
        };

      case 'is_milestone':
        return (task) => {
          if (task.type === $g.value.config.types.surrogate)
            return '';
          return task.type === $g.value.config.types.milestone ? $t('Yes') : $t('No');
        };

      case 'percent_work_complete':
      case 'percent_schedule_complete':
      case 'progress':
        return (task) => {
          if (task.type === $g.value.config.types.surrogate)
            return '';
          return `${Math.round(task[name] * 100)}%`;
        };

      case 'weight':
        return (task) => {
          if (task.type === $g.value.config.types.surrogate)
            return '';

          const weight_text = isNumber(task[name]) ? `${task[name]}%` : '-';

          if (![$g.value.config.types.project, $g.value.config.types.wbs].includes(task._original_type))
            return weight_text;

          const children_ids = $g.value.getChildren(task.id);
          let children_weight = 0;
          let has_a_weightless_child = false;

          for (const id of children_ids) {
            const child = $g.value.getTask(id);

            if (child.type === $g.value.config.types.surrogate)
              continue;

            if (!child?.weight) {
              has_a_weightless_child = true;
              break;
            }

            children_weight += child.weight;
          }

          children_weight = Number.parseFloat(children_weight.toFixed(2));

          if (has_a_weightless_child) {
            return `
              <span class="flex w-full items-center justify-between">
                ${weight_text}
                <span
                  class="grid-tippy [&>svg]:fill-warning-500"
                  data-tippy-placement="bottom"
                  data-tippy-theme="small-text"
                  data-tippy-content="${$t('One or more subtasks do not have valid weights. The weights will be equally distributed among the subtasks if not specified.')}"
                >
                  ${IconHawkInfoCircle}
                </span>
              </span>
            `;
          }

          if (children_weight !== 100) {
            const content = children_weight > 100
              ? `${$t('Total weight of subtasks cannot exceed 100%')} (${$t('Exceeding by')} ${(children_weight - 100).toFixed(2)}%)`
              : `${$t('Total weight of subtasks is not 100%')} (${$t('Remaining')}: ${(100 - children_weight).toFixed(2)}%)`;
            return `
              <span class="flex w-full items-center justify-between">
                ${weight_text}
                <span
                  class="grid-tippy [&>svg]:fill-red-500"
                  data-tippy-placement="bottom"
                  data-tippy-theme="small-text"
                  data-tippy-content="${content}"
                >
                  ${IconAlertTriangle}
                </span>
              </span>
            `;
          }

          return weight_text;
        };

      case 'absolute_remaining_duration':
      case 'absolute_work_rate':
        return (task) => {
          if (task.type === $g.value.config.types.surrogate)
            return '';
          return generateAbsoluteWorkRateTemplate(task, name);
        };

      default:
        return (task) => {
          if (task.type === $g.value.config.types.surrogate)
            return '';
          return generateDefaultTemplate(task, name, type);
        };
    }
  };

  const getColumnTemplate = ({ name, type }) => {
    if (name === 'select-columns') {
      return (task) => {
        if (is_schedule_editable.value && task.type !== $g.value.config.types.surrogate)
          return `<span class="gantt-context-menu cursor-pointer text-sm w-[39px] block h-full -ml-[6px]" data-task-id="${task.id}">...</span>`;
        return '';
      };
    }

    else if (name.startsWith('activity_code_')) {
      return (task) => {
        const code_name = name.replace('activity_code_', '');
        const found = task.activity_code_values.find(
          code => code.name === code_name,
        );
        return found?.value?.description ?? '';
      };
    }

    if (name.startsWith('custom_field_')) {
      return (task) => {
        if (task.type === $g.value.config.types.surrogate)
          return '';
        return generateCustomFieldTemplate(task, name);
      };
    }

    return templateForFixedFields(name, type);
  };

  function setupTemplates(search_results, current_match_index) {
    $g.value.templates.parse_date = date => new Date(date);
    $g.value.templates.format_date = date => $date(date, 'DATE_MED');
    $g.value.templates.tooltip_text = (_start, _end, _task) => '';
    $g.value.templates.grid_open = (item) => {
      if (['assigned', 'unassigned'].includes(item.id))
        return `<div class="gantt_tree_icon gantt_${item.$open ? 'close' : 'open'}"></div>`;
      if (item?.is_loading_children)
        return '<div class="gantt_tree_icon gantt_loading_children"></div>';
      const children = $g.value.getChildren(item.id);
      if (!children.length && item.$open)
        return '<div class="gantt_tree_icon gantt_open"></div>';
      return `<div class="gantt_tree_icon gantt_${item.$open ? 'close' : 'open'}"></div>`;
    };

    $g.value.templates.timeline_cell_class = function (_task, date) {
      // $g.value.config.show_task_cells must be true for this to work
      const classes = [];
      const from = dayjs(active_date_range.value.from);
      const to = dayjs(active_date_range.value.to);
      if (from.isValid() && to.isValid() && date >= new Date(from.format()) && date <= new Date(to.format()))
        classes.push('bg-gray-200');
      if (!$g.value.isWorkTime(date) && active_view.value.data.zoom_level === 4)
        classes.push('off-time');

      return classes.join(' ');
    };

    $g.value.templates.marker_class = (marker) => {
      if (
        (marker.name === 'today' && !active_view.value.data.feature_visibility.today_line)
        || (marker.name === 'data-date' && !active_view.value.data.feature_visibility.data_date_line)
        || (marker.name === 'deadline' && !active_view.value.data.feature_visibility.deadline_line)
      ) {
        return 'hidden';
      }
    };

    $g.value.templates.task_class = (_start, _end, task) => {
      let classes = [];

      const is_wbs = task._original_type === $g.value.config.types.wbs;
      const is_critical = $g.value.isCriticalTask(task);
      const is_overdue = task.progress < task.percent_schedule_complete;
      const is_completed = task.progress === 1;
      const is_critical_path_visible = active_view.value.data.feature_visibility.critical_path && !flags.value.is_task_being_dragged;
      const is_after_deadline = active_schedule.value.deadline ? dayjs(task.end_date).isAfter(active_schedule.value.deadline) : false;

      if (is_wbs)
        classes.push('gantt_bar_WBS', 'gantt_bar_WBS_orange_color');
      if (is_overdue)
        classes.push('pm-overdue');

      if (is_completed) {
        if (is_wbs)
          classes = classes.filter(item => item !== 'gantt_bar_WBS_orange_color');
        classes.push('pm-completed');
      }
      else if (is_critical_path_visible) {
        if (is_critical && is_wbs) {
          classes = classes.filter(item => item !== 'gantt_bar_WBS_orange_color');
        }
        else if (is_after_deadline) {
          classes.push('gantt_critical_task');
          if (is_wbs)
            classes = classes.filter(item => item !== 'gantt_bar_WBS_orange_color');
        }
      }

      return classes.join(' ');
    };

    $g.value.templates.link_class = (link) => {
      const classes = [];

      if (!active_view.value.data.feature_visibility.links)
        classes.push('hidden');

      const source_task = $g.value.getTask(link.source);
      const target_task = $g.value.getTask(link.target);
      if (
        active_view.value.data.feature_visibility.critical_path
        && source_task.progress === 1
        && target_task.progress === 1
      ) {
        classes.push('pm-completed-link');
      }

      return classes.join(' ');
    };

    $g.value.templates.grid_row_class = (_start, _end, task) => {
      const classes = [];

      if (search_results.value.includes(task.id))
        classes.push('pm-highlighted-grey');

      if (search_results.value[current_match_index.value] === task.id)
        classes.push('pm-highlighted');

      if (task.id === selected_task_id.value)
        classes.push('pm-selected');

      return classes.join(' ');
    };

    $g.value.templates.task_row_class = (_start, _end, task) => {
      if (task.id === selected_task_id.value)
        return 'pm-selected';
    };

    $g.value.templates.rightside_text = (_start, _end, task) => {
      if (!active_view.value.data.feature_visibility.activity_name) {
        if (active_view.value.data.feature_visibility.percentage_complete)
          return `${Math.round(task.progress * 100)}%`;
        return '';
      }

      let text = task.text;
      if ([$g.value.config.types.project, $g.value.config.types.wbs].includes(task._original_type)) {
        text = `${task.text}&nbsp;&nbsp;&mdash;&nbsp;&nbsp;<span>${$date(task.start_date, 'DATE_MED_WITH_WEEKDAY')} to ${$date(dayjs(task.start_date).isSame(dayjs(task.end_date), 'day')
          ? task.end_date
          : dayjs(task.end_date).subtract(1, 'day'), 'DATE_MED_WITH_WEEKDAY')}<span>`;
      }
      if (active_view.value.data.feature_visibility.percentage_complete)
        text += `&nbsp;&nbsp;&mdash;&nbsp;&nbsp;${Math.round(task.progress * 100)}%`;
      return text;
    };
    $g.value.templates.task_text = () => {
      return '';
    };
  }

  return {
    getColumnTemplate,
    setupTemplates,
  };
}
