<script setup>
// --------------------------------- Imports -------------------------------- //
import { sortBy } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/auth/stores/auth.store.js';
import { useChatStore } from '~/common/stores/chat.store.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

// ---------------------------------- Props --------------------------------- //

// ---------------------------------- Emits --------------------------------- //

// ---------------------------- Injects/Provides ---------------------------- //
const auth_store = useAuthStore();
const chat_store = useChatStore();
const project_management_store = useProjectManagementStore();

// ----------------------- Variables - Pinia - consts ----------------------- //
const { refetch_activity } = project_management_store;

// --------------------- Variables - Pinia - storeToRefs -------------------- //
const { is_fetching_comments_data } = storeToRefs(chat_store);
const { $g, active_task, is_schedule_editable, triggered_by } = storeToRefs(project_management_store);

// ------------------- Variables - Local - consts and lets ------------------ //
const user = auth_store?.logged_in_user_details?.user_id;

// ------------------------ Variables - Local - refs ------------------------ //

// ---------------------- Variables - Local - reactives --------------------- //
const state = reactive({
  done: false,
  is_loading: true,
  loaded_replies: [],
  is_loading_replies: true,
});

// --------------------------- Computed properties -------------------------- //
const comments = computed(() => {
  return sortBy(
    [...(chat_store?.comments || [])],
    d => (d?.createdAt ?? d?.created_at?.toISOString()),
  );
});

const comment_ids = computed(() => {
  return comments.value.map(comment => comment?.id).filter(Boolean);
});

const pinned_comments = computed(() => {
  return comments.value.filter(comment => comment?.pinned);
});

// -------------------------------- Functions ------------------------------- //
async function onCommentSent() {
  refetch_activity(active_task.value?.uid, ['last_comment', 'users_view_history']);
  $g.value.render();
}

async function setupCommentsStream() {
  await chat_store.set_data(`pm-${active_task.value?.uid}`, {
    name: active_task.value?.text,
    members: [user],
  });

  chat_store.chat_channel.on('message.new', setTimeout(() => {
    const element = document.getElementById('comments');
    if (element)
      element.scrollTop = element.scrollHeight;
  }, 20));
}

function onRepliesLoaded(id) {
  state.loaded_replies.push(id);
  state.done = comment_ids.value.every(id => state.loaded_replies.includes(id));
}

// -------------------------------- Watchers -------------------------------- //
watch(() => active_task.value?.uid, setupCommentsStream, { immediate: true });

// ----------------------------- Lifecycle Hooks ---------------------------- //
onMounted(() => {
  setTimeout(() => {
    if (!comments.value?.length)
      state.done = true;
  }, 1000);
});
</script>

<template>
  <HawkLoader v-if="is_fetching_comments_data" v-show="state.done" />
  <div
    v-else-if="(is_schedule_editable && comments.length) || !is_schedule_editable"
    :id="`comments${state.done ? '-done' : ''}`"
    class="pt-2 pb-2 pr-2 mt-2 -mb-2"
  >
    <div class="flex justify-between items-center">
      <div class="flex items-center text-md font-semibold text-gray-900">
        {{ $t('Comments') }}
      </div>
      <HawkPinnedCommentsPopover
        :comments="pinned_comments"
      />
    </div>
    <div v-for="comment in comments" :key="comment.id" class="w-full">
      <PmComment
        v-bind="is_schedule_editable
          ? { disabled_controls: ['settings', 'reply', 'pin', 'edit', 'delete', 'like'] }
          : {}"
        :comment="comment"
        :activity_uid="comment.id"
        class="w-full"
        @replies-loaded="onRepliesLoaded(comment.id)"
      />
    </div>
    <div v-if="!is_schedule_editable" id="hawk-comment-input" class="mt-3 pb-4">
      <PmCommentInput
        :key="`${triggered_by}-${state.done}`"
        :chat_store="chat_store"
        :user="user"
        :comment_input_config="{
          autofocus: state.done,
          disabled_controls: ['settings'],
        }"
        @send="onCommentSent"
      />
    </div>
  </div>
</template>
