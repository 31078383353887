import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { storeToRefs } from 'pinia';
import { computed, inject } from 'vue';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import { useTippy } from 'vue-tippy';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import useEmitter from '~/common/composables/useEmitter';
import { useCommonStore } from '~/common/stores/common.store';
import { getUserFullName } from '~/common/utils/common.utils';
import PmResourcesEditor from '~/project-management/components/inline-editors/pm-resources-editor.vue';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(quarterOfYear);

export function useHelpers() {
  const $t = inject('$t');
  const $toast = inject('$toast');

  const route = useRoute();
  const emitter = useEmitter();
  const common_store = useCommonStore();
  const project_management_store = useProjectManagementStore();

  const { update_activity, update_activity_backend, update_resource_assignments } = project_management_store;
  const {
    $g,
    is_fullscreen,
    active_view,
    active_schedule,
    // active_schedule_data,
    active_calendar,
    active_calendar_formatted,
    // resources_overloaded,
    // is_resources_panel_loading,
    // resource_mode,
    is_schedule_editable,
    resources_tippy,
    tippy_target,
  } = storeToRefs(project_management_store);

  const delete_popup = useModal({
    component: HawkDeletePopup,
  });

  const automation_types = computed(() => {
    return [
      [
        'planned_start',
        'Planned Start',
        // eslint-disable-next-line no-undef
        IconHawkRocketOne,
        'Automation based on planned start date',
      ],
      [
        'planned_finish',
        'Planned Finish',
        // eslint-disable-next-line no-undef
        IconHawkFlagOne,
        'Automation based on planned finish date',
      ],
      [
        'progress',
        'Progress',
        // eslint-disable-next-line no-undef
        IconHawkTrendUpOne,
        'Automation based on progress exceeding or lagging',
      ],
      [
        'status',
        'Status',
        // eslint-disable-next-line no-undef
        IconHawkClipboardCheck,
        'Automation based on change of status',
      ],
      [
        'frequency',
        'Frequency',
        // eslint-disable-next-line no-undef
        IconHawkCalendarCheckOne,
        'Automation based on frequency of days/weeks/months',
      ],
      [
        'custom_field',
        'Custom field',
        // eslint-disable-next-line no-undef
        IconHawkEditFive,
        'Automation based on custom field',
      ],
    ].map((item) => {
      return {
        value: item[0],
        label: $t(item[1]),
        icon: item[2],
        description: $t(item[3]),
      };
    });
  });

  if (window.gantt)
    window.gantt.$openDeleteTaskPopup = openDeleteTaskPopup;
  function openDeleteTaskPopup(task, after_delete = null) {
    delete_popup.patchOptions(
      {
        attrs: {
          header: $t('Delete activity'),
          content: `${$t('Are you sure you want to delete the activity')} - ${task.text}?`,
          button_text: $t('Delete'),
          options: { teleportTo: is_fullscreen.value ? '#pm-fullscreen-container' : 'body' },
          onClose() {
            delete_popup.close();
          },
          confirm: async () => {
            const parent_task_id = $g.value.getParent(task.id);
            const parent_task = $g.value.getTask(parent_task_id);
            const sibling_tasks = $g.value.getSiblings(task.id);

            if (parent_task._original_type === $g.value.config.types.project && sibling_tasks.length <= 2) {
              $toast({
                title: $t('Unable to delete this task.'),
                text: $t('The project must have at least one task.'),
                type: 'error',
              });
              delete_popup.close();
              return;
            }

            if (sibling_tasks.length <= 2) {
              parent_task.type = $g.value.config.types.task;
              parent_task._original_type = $g.value.config.types.task;
              $g.value.updateTask(parent_task.id);

              sibling_tasks.forEach((sibling_task) => {
                $g.value.deleteTask(sibling_task);
              });
            }
            else {
              $g.value.deleteTask(task.id);
            }

            if (after_delete)
              after_delete();

            delete_popup.close();
            $g.value.render();
          },
        },
      },
    );
    delete_popup.open();
  }

  function computeTotalUnitsRequired(start_date, end_date, resource, tasks, resource_assignments) {
    const parsed_start_date = dayjs(start_date);
    const parsed_end_date = dayjs(end_date).subtract(1, 'day');

    const computed_resource_assignments = resource_assignments ?? active_schedule.value.resource_assignments;

    const tasks_map = {};
    tasks.forEach((task) => {
      tasks_map[task.uid] = task;
    });

    return computed_resource_assignments
      .filter(assignment => assignment.resource === resource.uid && tasks_map[assignment.activity])
      .reduce((total, assignment) => {
        const task = tasks_map[assignment.activity];
        const task_start_date = dayjs(task.start_date);
        const task_end_date = dayjs(task.end_date).subtract(1, 'day');

        const effective_start_date = dayjs.max(parsed_start_date, task_start_date);
        const effective_end_date = dayjs.min(parsed_end_date, task_end_date);

        let working_days = 0;
        for (let d = effective_start_date; dayjs(d).isSameOrBefore(effective_end_date); d = d.add(1, 'day')) {
          if (!isNonWorkingDay(d))
            working_days++;
        }

        if (working_days > 0)
          return total + working_days * assignment.units_required;
        else
          return total;
      }, 0);
  }

  function isNonWorkingDay(date) {
    const day_of_the_week = date.get('day');
    const non_working_days = [];
    const days_of_the_week = [
      'SUNDAY',
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
    ];
    days_of_the_week.forEach((day, index) => {
      if (!active_calendar.value)
        return;
      if (!active_calendar.value.days[day].is_working)
        non_working_days.push(index);
    });
    return non_working_days.includes(day_of_the_week);
  };

  // function calculateTotalWorkingHoursPerDay(working_hours) {
  //   let total_working_hours_per_day = 0;
  //   for (const working_hour of working_hours) {
  //     const str = working_hour.replaceAll(':00', '');
  //     const split_numbers = str.split('-');
  //     const num_start = Number.parseInt(split_numbers[0], 10);
  //     const num_end = Number.parseInt(split_numbers[1], 10) || 24;
  //     total_working_hours_per_day += num_end - num_start;
  //   }
  //   return total_working_hours_per_day;
  // }

  // function calculateWorkingDays(start_date, end_date) {
  //   let working_days = 0;
  //   for (let d = dayjs(start_date); dayjs(d).isSameOrBefore(dayjs(end_date).subtract(1, 'day')); d = d.add(1, 'day'))
  //     if (!isNonWorkingDay(d))
  //       working_days++;
  //   return working_days;
  // }

  // function determineCssClass(tasks, total_units_required, total_working_hours_per_day, working_days, resource, is_hours) {
  //   const result = {
  //     css: [],
  //     workday_over: false,
  //   };
  //   if (resource_mode.value === 'hours' || is_hours)
  //     if (tasks.length && resource.cost_type === 'per_hour')
  //       if (total_units_required > total_working_hours_per_day * working_days) {
  //         result.css.push('workday_over');
  //         result.workday_over = true;
  //       }
  //       else {
  //         result.css.push('workday_ok');
  //       }
  //     else
  //       result.css.push('workday_neutral');

  //   else if (resource_mode.value === 'tasks')
  //     result.css.push('workday_neutral');

  //   return result;
  // }

  function computeResourceCellClass(start_date, _end_date, _resource, _tasks, _is_hours, _resource_assignments) {
    const css = ['resource_marker'];
    if (!$g.value.isWorkTime(start_date) && active_view.value.data.zoom_level === 4) {
      return {
        css: css.join(' '),
        workday_over: false,
      };
    }

    // const computed_resource_assignments = resource_assignments ?? active_schedule.value.resource_assignments;
    // const total_units_required = computeTotalUnitsRequired(start_date, end_date, resource, tasks, computed_resource_assignments);
    // const calendar_0 = active_schedule.value.calendars[0];
    // const working_hours = calendar_0.days.MONDAY.working_hours;
    // const total_working_hours_per_day = calculateTotalWorkingHoursPerDay(working_hours);
    // const working_days = calculateWorkingDays(start_date, end_date);

    // const result = determineCssClass(tasks, total_units_required, total_working_hours_per_day, working_days, resource, is_hours);
    css.push('workday_neutral');

    return {
      css: css.join(' '),
      workday_over: false, // result.workday_over,
    };
  }

  // function setupOverloadedResources(resources, resource_assignments, current_task, is_intermediate = false) {
  //   if (!active_schedule.value.track_resources) {
  //     if (!is_intermediate)
  //       resources_overloaded.value = [];
  //     return [];
  //   }

  //   is_resources_panel_loading.value = true;
  //   // If current_task is passed, use that range only for calculating overload. Otherwise, use the range of the first task (the PROJECT)
  //   const scheduled_start = current_task ? dayjs(current_task.start_date).startOf('day') : dayjs(active_schedule_data.value.data?.[0]?.start_date).startOf('day');
  //   const scheduled_end = current_task ? dayjs(current_task.end_date).startOf('day') : dayjs(active_schedule_data.value.data?.[0]?.end_date).startOf('day');

  //   let computed_resources_overloaded = cloneDeep(resources_overloaded.value);

  //   let computed_resources = resources;
  //   if (!resources) {
  //     computed_resources = active_schedule.value.resources;
  //     computed_resources_overloaded = {};
  //   }
  //   computed_resources.forEach((resource) => {
  //     delete computed_resources_overloaded[resource.uid];
  //     let current_start = scheduled_start;
  //     // Gets the resource assignments for the current resource
  //     let tasks = active_schedule_data.value.data.filter(task => task.resources?.includes?.(resource.uid));
  //     if (current_task)
  //       tasks = [...tasks, current_task];
  //     while (tasks.length && dayjs(current_start).isSameOrBefore(scheduled_end)) {
  //       const result = computeResourceCellClass(current_start.toDate(), current_start.add(1, 'day'), resource, tasks, true, resource_assignments);
  //       if (result.workday_over)
  //         tasks.forEach((task) => {
  //           if (
  //             dayjs(current_start).isBetween(task.start_date, task.end_date, 'day', '[]')
  //           )
  //             if (computed_resources_overloaded[resource.uid]) {
  //               if (!computed_resources_overloaded[resource.uid].includes(task.uid))
  //                 computed_resources_overloaded[resource.uid].push(task.uid);
  //             }
  //             else {
  //               computed_resources_overloaded[resource.uid] = [task.uid];
  //             }
  //         });

  //       current_start = current_start.add(1, 'day');
  //     }
  //   });
  //   is_resources_panel_loading.value = false;

  //   if (!is_intermediate)
  //     resources_overloaded.value = computed_resources_overloaded;
  //   return computed_resources_overloaded;
  // }

  const members = computed(() => {
    const asset_id = route.params.asset_id;
    let member_items = [];
    member_items = common_store.scope_users(asset_id);
    member_items = member_items.filter(item => item.status !== 'deprovisioned');

    const result = member_items?.map((user) => {
      return {
        label: getUserFullName(user),
        uid: user.uid,
        member: true,
        ...common_store.get_user(user.uid),
      };
    });

    return result;
  });

  const teams = computed(() => {
    const asset_id = route.params.asset_id;
    let team_items = [];

    team_items = common_store.scope_teams(asset_id);

    const result = team_items?.map((team) => {
      return {
        name: team.name,
        label: team.name,
        uid: team.uid,
        member: false,
        ...common_store.get_team(team.uid),
      };
    });

    return result;
  });

  const hideOnEsc = {
    name: 'hideOnEsc',
    defaultValue: true,
    fn({ hide }) {
      function onKeyDown(event) {
        if (event.keyCode === 27)
          hide();
      }

      return {
        onShow() {
          document.addEventListener('keydown', onKeyDown);
        },
        onHide() {
          document.removeEventListener('keydown', onKeyDown);
        },
      };
    },
  };

  function generateScheduleResourceMap(resources) {
    return resources?.map?.((item) => {
      return active_schedule.value?.resources?.find?.(el => el.uid === item);
    })?.map?.((item) => {
      return {
        ...item,
        team: common_store.is_type_team(item?.external_id),
      };
    });
  }

  function getResourceUids(all_resources) {
    return all_resources.map(item => item.uid);
  }

  function generateBodyForUpdatingActivityBackend(all_resources) {
    return {
      resource_assignments: all_resources.map((item) => {
        return {
          resource: item.uid,
          units_required: item.work || 0,
        };
      }),
    };
  }

  function openResourcesEditor(rect, task_id) {
    if (resources_tippy.value) {
      resources_tippy.value.destroy();
      resources_tippy.value = null;
      $g.value.ext.inlineEditors.hide();
    }
    const task = $g.value.getTask(task_id);
    if (!task.actions.modify_resources)
      return;
    const resource_assignments = active_schedule.value?.resource_assignments;
    const resources = generateScheduleResourceMap(task.resources);

    // let computed_overloaded_resources = resources_overloaded.value;

    resources_tippy.value = useTippy(() => rect ? document.body : document.getElementById('activity-details-resources-editor'), {
      theme: 'pm-resource-editor',
      appendTo: tippy_target.value,
      // eslint-disable-next-line no-undef
      content: h(PmResourcesEditor, {
        task,
        assignees: resources,
        members: members.value,
        teams: teams.value,
        is_schedule_editable: is_schedule_editable.value,
        active_schedule: active_schedule.value,
        active_calendar_formatted: active_calendar_formatted.value,
        resource_assignments,
        // resources_overloaded: cloneDeep(computed_overloaded_resources),
        translate: (key) => {
          return $t(key);
        },
        // on_calculate_overload: (all_resources, do_union = true) => {
        //   const all_resource_uids = getResourceUids(all_resources);

        //   let union_resources = union(do_union ? task.resources : [], all_resource_uids);
        //   union_resources = union_resources.map((resource_uid) => {
        //     return active_schedule.value.resources.find(resource => resource.uid === resource_uid);
        //   });

        //   let resource_assignments = cloneDeep(active_schedule.value.resource_assignments);
        //   all_resources.forEach((assignee) => {
        //     const assignment_index = resource_assignments.findIndex(
        //       assignment => assignment.activity === task.uid && assignment.resource === assignee.uid,
        //     );

        //     if (assignment_index === -1)
        //       resource_assignments.push({
        //         activity: task.uid,
        //         resource: assignee.uid,
        //         units_required: assignee.work || 0,
        //       });
        //     else if (assignee.work && resource_assignments[assignment_index].units_required !== assignee.work)
        //       resource_assignments[assignment_index].units_required = assignee.work;
        //   });

        //   resource_assignments = resource_assignments.filter(
        //     (assignment) => {
        //       if (assignment.activity === active_task_uid.value)
        //         return all_resources.some(assignee => assignment.resource === assignee.uid);
        //       else
        //         return true;
        //     },
        //   );

        //   computed_overloaded_resources = setupOverloadedResources(union_resources, resource_assignments, task, true);
        //   return computed_overloaded_resources;
        // },
        on_close: async () => {
          resources_tippy.value.hide();
          $g.value.ext.inlineEditors.hide();
        },
        on_save: async (all_resources) => {
          const all_resource_uids = getResourceUids(all_resources);

          task.resources = all_resource_uids;
          $g.value.updateTask(task_id);

          active_schedule.value.activities[task.uid] = {
            ...active_schedule.value.activities[task.uid],
            resources: all_resource_uids,
          };

          update_resource_assignments(all_resources, task.uid);

          if (!is_schedule_editable.value) {
            const body = generateBodyForUpdatingActivityBackend(all_resources);
            const data = await update_activity_backend(task.uid, body);
            data.data.forEach((activity) => {
              update_activity({
                uid: activity.uid,
                planned_cost: activity.planned_cost,
              });
            });
          }
          // resources_overloaded.value = current_resources_overloaded;
          $g.value.render();
        },
      }),
      plugins: [hideOnEsc],
      showOnCreate: true,
      arrow: false,
      interactive: true,
      animation: false,
      trigger: 'manual',
      hideOnClick: false,
      maxWidth: 649,
      ...(
        rect
          ? {
              placement: 'bottom-start',
              offset: [0, -30],
              getReferenceClientRect() {
                return rect;
              },
            }
          : {
              placement: 'bottom-end',
            }
      ),
    });

    emitter.on('hide_resources_editor', () => {
      if (resources_tippy.value) {
        resources_tippy.value.destroy();
        resources_tippy.value = null;
        $g.value.ext.inlineEditors.hide();
      }
    });
  }

  return {
    openDeleteTaskPopup,
    automation_types,
    computeTotalUnitsRequired,
    computeResourceCellClass,
    // setupOverloadedResources,
    openResourcesEditor,
  };
}
