<script setup>
import { debounce } from 'lodash-es';
import { inject } from 'vue';
import { useModal } from 'vue-final-modal';
import DocumentBrowsePopup from '~/dms/components/atoms/document-browse-popup.vue';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
  validator: {
    type: Function,
    default: () => () => true,
  },
});

const emit = defineEmits(['update:modelValue']);

const state = reactive({
  documents: [],
  document_status: [],
  completion_status: {},
  document_weights: {},
  is_loading: false,
});

const $t = inject('$t');
const $services = inject('$services');
const browse_popup = useModal({
  component: DocumentBrowsePopup,
});

const dms_settings_store = useDMSSettingsStore();

const remaining_statuses = computed(() => {
  const all_statuses = dms_settings_store.document_status;
  const document_statuses = state.document_status.map(status => status.uid);
  return all_statuses.filter(status => !document_statuses.includes(status.uid));
});
const total_weights = computed(() => {
  if (Object.keys(state.document_weights).filter(document_id => state.document_weights[document_id]).length === 0)
    return 100;
  return Object.values(state.document_weights).reduce((acc, curr) => acc + (+curr || 0), 0);
});

const status_form$ = ref(null);
const document_form$ = ref(null);

function openBrowsePopup() {
  browse_popup.patchOptions({
    attrs: {
      title: $t('Select documents from Files'),
      action_label: $t('Upload Documents'),
      on_submit: ({ selected_documents = [] }) => {
        state.documents.push(...selected_documents);
        browse_popup.close();
      },
      onClose() {
        browse_popup.close();
      },
    },
  });
  browse_popup.open();
}

const updateModel = debounce(() => {
  const files = {};
  const has_no_weights = Object.keys(state.document_weights).filter(document_id => state.document_weights[document_id]).length === 0;

  state.documents.forEach((document, index) => {
    if (has_no_weights) {
      const equal_weight = (1 / state.documents.length).toFixed(3);
      if (state.documents.length > 1 && index === state.documents.length - 1)
        files[document.uid] = +(1 - (equal_weight * (state.documents.length - 1))).toFixed(3);
      else files[document.uid] = +equal_weight;
    }
    else {
      files[document.uid] = +((state.document_weights[document.uid] || 0) / 100);
    }
  });

  const custom_progress = state.completion_status?.has_status_percentage;
  const status_config = state.completion_status?.config || {};
  const status_config_array = Object.keys(status_config);
  const config = {};

  status_config_array.forEach((status_uid) => {
    config[status_uid] = (status_config[status_uid]?.percentage || 100) / 100;
  });

  const payload = {
    type: 'dms_file_progress',
    weighted_average: true,
    files,
    statuses: {
      custom_progress,
      config,
    },
  };

  emit('update:modelValue', payload);

  const forms_array = [status_form$, document_form$];
  const has_document_status = state.document_status.length > 0;
  const is_forms_valid = forms_array.every(form => !form.value.invalid);
  const has_documents = state.documents.length > 0;
  const has_valid_total_weights = total_weights.value === 100;
  const is_valid = has_document_status && is_forms_valid && has_documents && has_valid_total_weights;
  props.validator(is_valid);
}, 200);

function getDefaultStatusConfig(status) {
  if (props.modelValue?.statuses?.config[status.uid]) {
    return props.modelValue?.statuses?.config[status.uid] * 100;
  }
  return 100;
}

function getDefaultDocumentWeight(document) {
  if (props.modelValue?.files?.[document.uid]) {
    return props.modelValue?.files?.[document.uid] * 100;
  }
}

function prefillStatusConfig() {
  const modelValue = props.modelValue;
  const document_status = dms_settings_store.document_status.filter(status => modelValue.statuses.config[status.uid]);
  const config = {};
  document_status.forEach((status) => {
    config[status.uid] = modelValue.statuses.config[status.uid] * 100;
  });
  state.completion_status = {
    has_status_percentage: modelValue.statuses.custom_progress,
    config: { ...modelValue.statuses.config },
  };
  state.document_status = document_status;
}

function prefillDocuments(documents = []) {
  state.documents = documents;
}

async function getData() {
  state.is_loading = true;
  try {
    const modelValue = props.modelValue;

    const status_promise = dms_settings_store.fetch_all_statuses();

    if (!modelValue.statuses)
      return state.is_loading = false;

    const documents_promise = $services.documents.getAll(
      {
        query: {
          uid: Object.keys(modelValue.files),
        },
      },
    );

    await Promise.all([status_promise, documents_promise]);
    const { data } = await documents_promise;

    prefillStatusConfig();
    prefillDocuments(data?.details);
  }
  catch (error) {
    logger.log(error);
  }
  state.is_loading = false;
}

getData();
</script>

<template>
  <div
    v-if="!state.is_loading"
    class="max-h-[calc(100vh-300px)] scrollbar relative w-[550px]"
  >
    <div class="text-sm font-normal text-gray-600 mb-4">
      {{ $t('pm-auto-update-progress-dms-sync-text-1') }}
      <br>
      <br>
      <span class="font-semibold text-gray-800">
        {{ $t('Note') }}:
      </span>
      {{ $t('pm-auto-update-progress-dms-sync-text-2') }}
    </div>
    <vueform ref="document_form$" v-model="state.document_weights" size="sm" :display-errors="false" @updated="updateModel">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-2 text-sm font-semibold text-gray-600">
          <div class="col-span-8">
            {{ $t('Documents') }}
          </div>
          <div class="col-span-2 text-center">
            {{ $t('Weight') }}(%)
          </div>
          <div class="col-span-2 text-center">
            {{ $t('Actions') }}
          </div>
        </div>
        <div v-for="(document, index) in state.documents" :key="document.uid" class="grid gap-2 grid-cols-12 items-center mt-4">
          <div class="col-span-8">
            <DocumentBreadcrumbs
              :active_item="document" :is_file_details="true"
            />
            <hawk-text :content="document.name" :length="40" class="text-sm font-semibold text-gray-600" />
            <div v-if="document.number" class="text-xs font-normal text-gray-600">
              #{{ document.number }}
            </div>
          </div>
          <div class="col-span-2">
            <TextElement
              :name="document.uid"
              type="number" input-type="number"
              :messages="{ min: 'Invalid value', max: 'Invalid value', numeric: 'Invalid value' }"
              :attrs="{ min: 0, max: 100 }"
              rules="numeric|nullable|min:0|max:100"
              :default="getDefaultDocumentWeight(document)"
              :addons="{ after: '%' }"
              :add-classes="{
                ElementError: {
                  container: '-mb-4',
                },
              }"
            />
          </div>
          <div class="col-span-2 flex justify-center items-center">
            <hawk-button type="text" @click="state.documents.splice(index, 1);">
              <icon-hawk-trash-one class="text-gray-500" />
            </hawk-button>
          </div>
        </div>
        <hawk-button type="text" class="mt-2" @click="openBrowsePopup">
          <icon-hawk-plus />
          {{ $t('Add files') }}
        </hawk-button>
      </div>
    </vueform>
    <HawkAlertBanner v-if="state.documents.length === 0" class="mt-2" color="warning">
      <template #icon>
        <IconHawkAlertTriangle />
      </template>
      <template #content>
        <div>
          {{ $t("Add atleast one document here") }}
        </div>
      </template>
    </HawkAlertBanner>
    <HawkAlertBanner v-else-if="total_weights !== 100" class="mt-2" color="warning">
      <template #icon>
        <IconHawkAlertTriangle />
      </template>
      <template #content>
        <div>
          {{ $t("Total weights of documents do not sum to 100%") }}
        </div>
      </template>
    </HawkAlertBanner>
    <div class="border-t my-6" />
    <vueform ref="status_form$" v-model="state.completion_status" :sync="true" size="sm" :display-errors="false" @updated="updateModel">
      <div class="col-span-12 text-grey-600">
        <div class="flex justify-between">
          <div class="text-md font-semibold">
            {{ $t('Completion status') }}
          </div>
          <div class="flex items-center text-sm font-medium">
            {{ $t('Status percentages') }}
            <ToggleElement name="has_status_percentage" class="ml-2" />
          </div>
        </div>
        <div class="text-sm font-normal mb-4 mt-2">
          {{ $t('Select completion statuses for the documents selected') }}
        </div>
        <ObjectElement name="config">
          <ObjectElement v-for="(status, index) in state.document_status" :key="status.uid" :name="status.uid">
            <div class="grid grid-cols-12 col-span-12 items-center gap-2 my-2">
              <div class="col-span-8 flex items-center">
                <div class="w-3 h-3 rounded-full mr-2 flex-0" :style="`background:${status.color}`" />
                <SelectElement
                  class="flex-1"
                  name="status"
                  append-to="#pm-update-auto-progress-modal"
                  :object="true"
                  :native="false"
                  value-prop="uid"
                  :default="status"
                  :can-clear="false"
                  :can-deselect="false"
                  label-prop="name"
                  :items="remaining_statuses"
                  @change="state.document_status[index] = $event"
                />
              </div>
              <div v-if="state.completion_status?.has_status_percentage" class="col-span-2 flex justify-center items-center">
                <TextElement
                  class="flex-1"
                  name="percentage"
                  type="number"
                  :default="getDefaultStatusConfig(status)" :attrs="{ min: 0, max: 100 }"
                  rules="numeric|min:0|max:100"
                  :messages="{ min: 'Invalid value', max: 'Invalid value', numeric: 'Invalid value' }"
                  :add-classes="{
                    ElementError: {
                      container: '-mb-4',
                    },
                  }"
                  input-type="number"
                  :addons="{ after: '%' }"
                />
              </div>
              <div class="col-span-2 flex justify-center items-center">
                <hawk-button type="text" @click="state.document_status.splice(index, 1)">
                  <icon-hawk-trash-one class="text-gray-500" />
                </hawk-button>
              </div>
            </div>
          </ObjectElement>
        </ObjectElement>
        <HawkAlertBanner v-if="state.document_status.length === 0" class="mt-2" color="warning">
          <template #icon>
            <IconHawkAlertTriangle />
          </template>
          <template #content>
            <div>
              {{ $t("Add atleast one status here") }}
            </div>
          </template>
        </HawkAlertBanner>
        <hawk-button v-if="remaining_statuses.length > 0" type="text" class="mt-2" @click="state.document_status.push(remaining_statuses[0]);updateModel();">
          <icon-hawk-plus />
          {{ $t('Add status') }}
        </hawk-button>
      </div>
    </vueform>
  </div>
  <div v-else>
    <hawk-loader :show="state.is_loading" />
  </div>
</template>
