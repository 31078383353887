import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';
import { useCommonImports } from '~/common/composables/common-imports.composable';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

export function useZoom() {
  const project_management_store = useProjectManagementStore();
  const { $t } = useCommonImports();

  const { modify_config, set_view_dirtiness } = project_management_store;
  const { $g, active_view, active_schedule } = storeToRefs(project_management_store);

  const hours_labels = {
    0: $t('Hours per year'),
    1: $t('Hours per quarter'),
    2: $t('Hours per month'),
    3: $t('Hours per week'),
    4: $t('Hours per day'),
  };

  const tasks_labels = {
    0: $t('Tasks per year'),
    1: $t('Tasks per quarter'),
    2: $t('Tasks per month'),
    3: $t('Tasks per week'),
    4: $t('Tasks per day'),
  };

  const zoom_level = computed(() => active_view.value.data.zoom_level);

  watch(zoom_level, (level) => {
    $g.value.ext.zoom.setLevel(level);
    const hours_radio_label = document.querySelector('label[for="hours-radio"]');
    hours_radio_label && (hours_radio_label.innerHTML = hours_labels[level]);
    const tasks_radio_label = document.querySelector('label[for="tasks-radio"]');
    tasks_radio_label && (tasks_radio_label.innerHTML = tasks_labels[level]);
    // setLayout(); // NOTE: removed for performance and also because it scrolls horizontally to the start, but it shouldn't be needed here
    $g.value.render();
  });

  const zoom_levels = {
    levels: [
      {
        name: 'year',
        min_column_width: 30,
        scales: [
          { unit: 'year', step: 1, format: '%Y' },
        ],
      },
      {
        name: 'quarter',
        min_column_width: 55,
        scales: [
          { unit: 'year', step: 1, format: '%Y' },
          {
            unit: 'quarter',
            step: 1,
            format: (date) => {
              const dts = $g.value.date.date_to_str('%M');
              const end = $g.value.date.add(
                $g.value.date.add(date, 3, 'month'),
                -1,
                'day',
              );
              return `${dts(date)} - ${dts(end)}`;
            },
          },
        ],
      },
      {
        name: 'month',
        min_column_width: 70,
        scales: [
          { unit: 'year', step: 1, format: '%Y' },
          { unit: 'month', step: 1, format: '%F' },
        ],
      },
      {
        name: 'week',
        min_column_width: 44,
        scales: [
          { unit: 'month', step: 1, format: '%F %Y' },
          {
            unit: 'week',
            step: 1,
            format: date => `W${$g.value.date.date_to_str('%W')(date)}`,
          },
        ],
      },
      {
        name: 'day',
        min_column_width: 26,
        scales: [
          { unit: 'month', step: 1, format: '%F %Y' },
          {
            unit: 'day',
            step: 1,
            format: '%d',
            css(date) {
              if (dayjs(dayjs().tz(active_schedule.value.timezone).utc(true)).toISOString().split('T')[0] === dayjs(dayjs(date).utc(true)).toISOString().split('T')[0])
                return 'today_scale_cell_class';

              if (!$g.value.isWorkTime(date) && active_view.value.data.zoom_level === 4)
                return 'off-time';
            },
          },
        ],
      },
    ],
    useKey: 'ctrlKey',
    trigger: 'wheel',
    handler: (event) => {
      event.preventDefault();
      let level = zoom_level.value;
      if (event.deltaY < 0 && zoom_level.value < 4)
        level += 1;
      else if (event.deltaY > 0 && zoom_level.value > 0)
        level -= 1;
      modify_config({ key: 'zoom_level', value: level });
      set_view_dirtiness(true);
    },
    element: () => $g.value.$root.querySelector('.gantt_task'),
  };

  function setupZoomLevels() {
    $g.value.ext.zoom.init(zoom_levels);
    $g.value.ext.zoom.setLevel(zoom_level.value);
  }

  return {
    zoom_level,
    setupZoomLevels,
  };
}
