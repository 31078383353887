<script setup>
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { isEmpty, size, toArray } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { truncate } from '~/common/filters/truncate.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const emit = defineEmits(['close']);

const $t = inject('$t');
const $date = inject('$date');

const project_management_store = useProjectManagementStore();

const { active_schedule, active_schedule_sync_history } = storeToRefs(project_management_store);

const state = reactive({
  active_page: 'sync_history',
  activities_data: [],
  current_activity_id: 0,
});

dayjs.extend(relativeTime);

const badge_color_map = {
  'Completed': 'green',
  'In Progress': 'blue',
  'Not Started': 'gray',
};

watch(() => state.active_page, async () => {
  await nextTick();
  if (state.active_page === 'sync_history') {
    const el = document.getElementById(state.current_activity_id);
    if (el) {
      el.scrollIntoView({
        behavior: 'instant',
        block: 'center',
      });
    }
    state.current_activity_id = 0;
  }
  else {
    const el = document.getElementById('activities_list');
    if (el) {
      el.scrollIntoView({
        behavior: 'instant',
      });
    }
  }
});

function hasAnythingChanged(properties) {
  return Object.keys(properties).some(property => !isEmpty(properties[property]));
}

function goToActivities(data) {
  state.active_page = 'activities';
  state.activities_data = {
    ...data.properties.activities_completed,
    ...data.properties.activities_started,
    ...data.properties.activities_updated,
  };
  state.current_activity_id = data.id;
}

function getHierarchy(uid) {
  const parents = [];
  const gantt = window.gantt;
  const id = active_schedule.value.activities[uid];
  gantt.eachParent((task) => {
    parents.push({
      value: task.uid,
      label: task.name,
      id: task.id,
      has_children: true,
    });
  }, id);
  return parents.reverse();
}
</script>

<template>
  <HawkModalContainer>
    <HawkModalHeader @close="emit('close')">
      <template #title>
        <div v-if="state.active_page === 'sync_history'" class="text-gray-900">
          {{ $t("Sync history") }}
        </div>
        <div v-else class="flex items-center h-7 text-gray-900">
          <div
            class="text-gray-500 rounded-md cursor-pointer flex justify-center items-center p-2 mr-1 hover:bg-gray-50 hover:text-gray-900"
            @click="state.active_page = 'sync_history'"
          >
            <IconHawkArrowLeft />
          </div>
          {{ $t("Activities") }}
        </div>
      </template>
    </HawkModalHeader>
    <HawkModalContent class="h-[400px] mt-6 !pt-0" :class="{ '!h-[270px]': state.active_page === 'sync_history' && !active_schedule_sync_history.length }">
      <HawkActivityFeed v-if="state.active_page === 'sync_history' && active_schedule_sync_history.length" :activities="active_schedule_sync_history">
        <template #icon>
          <IconHawkCheckCircle class="text-success-600 w-4 h-4" />
        </template>
        <template #content="{ data }">
          <div :id="data.id" class="w-full">
            <div class="flex items-center justify-between w-full">
              <div>
                <div class="flex items-center mb-1 text-xs font-regular text-gray-600">
                  <HawkMembers v-if="data.type === 'manual'" :members="data.user.uid" class="mr-2" type="badge" />
                  <div v-else class="text-xs font-semibold text-gray-700 mr-2 py-[3px]">
                    {{ $t('Auto sync') }}
                  </div>
                  <div
                    v-if="dayjs().diff(data.timestamp, 'day') < 1"
                    class="first-letter:capitalize text-xs font-regular text-gray-600"
                  >
                    {{ $date(data.timestamp, 'DATETIME_LONG') }}
                  </div>
                  <div
                    v-else
                    class="text-xs font-regular text-gray-600"
                  >
                    {{ dayjs(data.timestamp).format('MMM DD, YYYY h:mm a') }}
                  </div>
                </div>
                <div class="text-xs font-regular text-gray-600">
                  <span v-if="data.type === 'manual'">
                    {{ $t('Manually triggered the sync') }}.
                  </span>
                  <span v-else>
                    {{ $t('Schedule automatically triggered successfully') }}.
                  </span>
                  <template v-if="hasAnythingChanged(data.properties)">
                    {{ size(data.properties.activities_started) }} {{ $t('activities started') }},
                    {{ size(data.properties.activities_completed) }} {{ $t('completed') }},
                    {{ size(data.properties.activities_updated) }} {{ $t('updated') }}.
                  </template>
                </div>
              </div>
              <div
                v-if="hasAnythingChanged(data.properties)"
                class="text-gray-700 rounded-md cursor-pointer flex justify-center items-center p-2 hover:bg-gray-50 hover:text-gray-900"
                @click="goToActivities(data)"
              >
                <IconHawkChevronRight />
              </div>
            </div>
          </div>
        </template>
      </HawkActivityFeed>
      <div v-else-if="state.active_page === 'sync_history' && !active_schedule_sync_history.length" class="text-md font-regular text-gray-700">
        <hawk-illustrations type="no-data" for="pm_sync_history" :has_fixed_height="false" />
      </div>
      <div v-else id="activities_list" class="w-full">
        <div v-for="activity in toArray(state.activities_data)" :key="activity" class="border border-gray-200 rounded-lg p-4 w-full mb-4">
          <div class="flex flex-wrap">
            <div v-for="(hierarchy_item, hierarchy_item_idx) in getHierarchy(activity.uid)" :key="hierarchy_item.id" class="flex items-center">
              <div class="text-xs font-regular text-gray-600">
                <div
                  v-if="hierarchy_item.label.length > 15"
                  v-tippy="{
                    content: hierarchy_item.label,
                    placement: 'top',
                  }"
                >
                  {{ truncate(hierarchy_item.label, 10) }}
                </div>
                <template v-else>
                  {{ hierarchy_item.label }}
                </template>
              </div>
              <IconHawkChevronRight v-if="hierarchy_item_idx !== getHierarchy(activity.uid).length - 1" class="text-gray-300 mx-1" />
            </div>
          </div>
          <div class="text-sm font-semibold text-gray-900 my-1">
            {{ active_schedule.activities[activity.uid].name }}
          </div>
          <div class="flex justify-between">
            <div>
              <HawkBadge :color="badge_color_map[activity.before.status]">
                {{ $t(activity.before.status) }}
              </HawkBadge>
              <template v-if="activity.after.status !== activity.before.status">
                <IconHawkArrowRight class="mx-2 inline text-gray-600" />
                <HawkBadge :color="badge_color_map[activity.after.status]">
                  {{ $t(activity.after.status) }}
                </HawkBadge>
              </template>
            </div>
            <div class="text-sm font-regular text-gray-900 flex items-center">
              {{ Math.round(activity.before.progress * 100) }}%
              <template v-if="activity.after.progress !== activity.before.progress">
                <IconHawkArrowRight class="mx-2 inline text-gray-600" />
                {{ Math.round(activity.after.progress * 100) }}%
              </template>
            </div>
          </div>
        </div>
      </div>
    </HawkModalContent>
  </HawkModalContainer>
</template>
