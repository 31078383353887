<script setup>
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { toRaw } from 'vue';
import { useModal } from 'vue-final-modal';
import HawkDatePickerModal from '~/common/components/organisms/hawk-datepicker-modal.vue';
import { useCommonStore } from '~/common/stores/common.store';
import { getUserFullName } from '~/common/utils/common.utils';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const emit = defineEmits(['close']);

const $t = inject('$t');

const common_store = useCommonStore();
const project_management_store = useProjectManagementStore();
const { $g, active_view, views, active_schedule, is_fullscreen, is_schedule_editable } = storeToRefs(project_management_store);
const { set_view_dirtiness, modify_filter, set_active_task_uid } = project_management_store;

const status_options = [
  ['Not started', 'not-started'],
  ['Started', 'started'],
  ['Finished', 'finished'],
].map((item) => {
  return {
    label: $t(item[0]),
    name: item[0],
    value: item[1],
  };
});

const timerange_options = [
  ['This week', 'week', dayjs().format('YYYY-MM-DD'), dayjs().add(7, 'day').format('YYYY-MM-DD')],
  ['This month', 'month', dayjs().format('YYYY-MM-DD'), dayjs().add(30, 'day').format('YYYY-MM-DD')],
  ['This year', 'year', dayjs().format('YYYY-MM-DD'), dayjs().add(365, 'day').format('YYYY-MM-DD')],
  ['Custom', 'custom', null, null],
].map(item => ({
  name: item[0],
  label: $t(item[0]),
  value: item[1],
  range: [item[2], item[3]],
}));

const state = reactive({
  form_values: {},
  date_range_cache: [null, null],
  selected_date_range: [null, null],
  prevent_custom_date_popup: false,
});

const form$ = ref(null);

const date_range_modal = useModal({
  component: HawkDatePickerModal,
  attrs: {
    options: { teleportTo: is_fullscreen.value ? '#pm-fullscreen-container' : 'body' },
    onClose() {
      state.prevent_custom_date_popup = true;
      form$.value.el$('timerange').update(findInTimeRange(state.date_range_cache)?.value ?? null);
      state.selected_date_range = state.date_range_cache;
      setTimeout(() => {
        state.prevent_custom_date_popup = false;
      }, 300);
      date_range_modal.close();
    },
    onOkay(range) {
      state.selected_date_range = range;
      date_range_modal.close();
    },
  },
});

const all_resources_list = computed(() => {
  return active_schedule.value.resources.map((resource) => {
    if (resource.type === 'custom')
      resource.search_uid = resource.name;
    else if (resource.type === 'member')
      resource.search_uid = getUserFullName(common_store.get_user(resource.external_id));
    return resource;
  });
});

const default_filters = computed(() => {
  const state = $g.value.getState();
  return {
    ...views.value[0].filters,
    date_range: [state.min_date, state.max_date],
  };
});

const activity_codes = computed(() => {
  return active_schedule.value.activity_codes?.filter?.(code => code.values.length);
});

const date_range_text = computed(() => {
  const range = state.selected_date_range;
  const found = findInTimeRange(range);
  if (!found)
    return '';

  let text = found.label;
  if (found.value === 'custom')
    text += `: ${dayjs(range[0]).format('YYYY-MM-DD')} - ${dayjs(range[1]).format('YYYY-MM-DD')}`;
  return text;
});

function setTimerangeFilter(type) {
  if (type !== 'custom') {
    const item = timerange_options.find(i => i.value === type);
    state.selected_date_range = [...item?.range ?? [null, null]];
  }
  else if (!state.prevent_custom_date_popup) {
    state.selected_date_range = [];
    date_range_modal.open();
  }
}

function areDateRangesEqual(range_a, range_b) {
  return (dayjs(range_a[0]).format('YYYY-MM-DD') === dayjs(range_b[0]).format('YYYY-MM-DD'))
    && (dayjs(range_a[1]).format('YYYY-MM-DD') === dayjs(range_b[1]).format('YYYY-MM-DD'));
}

function findInTimeRange(range) {
  if (!range || !dayjs(range[0]).isValid() || !dayjs(range[1]).isValid() || areDateRangesEqual(range, default_filters.value.date_range))
    return null;
  const found = timerange_options.find(item => areDateRangesEqual(item.range, range));
  return found || timerange_options[3];
}

function mapFilterOptions(options) {
  return options.map(o => ({
    value: o.name,
    label: o.description,
    name: o.description,
  }));
}

function onCustomClicked() {
  state.date_range_cache = [...(toRaw(state.selected_date_range) ?? [])];
  date_range_modal.open();
}

function saveFilters() {
  set_view_dirtiness(true);
  const filters = {
    ...active_view.value.data.filters,
    ...state.form_values,
    date_range: state.selected_date_range ?? [null, null],
    milestone: state.form_values.type === 'milestone',
    activity: state.form_values.type === 'activity',
    critical: state.form_values.critical === 'yes',
    not_critical: state.form_values.critical === 'no',
  };
  delete filters.type;
  delete filters.timerange;
  modify_filter(filters);
  set_active_task_uid(null);
  emit('close');
}

function setInitialFilters(el$) {
  const filters = { ...active_view.value.data.filters };
  state.selected_date_range = filters.date_range || default_filters.value.date_range;
  delete filters.date_range;
  state.prevent_custom_date_popup = true;
  let type = 'any';
  if (filters.milestone)
    type = 'milestone';
  else if (filters.activity)
    type = 'activity';

  let critical = 'any';
  if (filters.critical)
    critical = 'yes';
  else if (filters.not_critical)
    critical = 'no';

  if (!filters.progress_updates)
    filters.progress_updates = '';

  el$.load({
    ...filters,
    timerange: findInTimeRange(state.selected_date_range)?.value ?? null,
    type,
    critical,
  }, true);

  setTimeout(() => {
    state.prevent_custom_date_popup = false;
  }, 300);
}
</script>

<template>
  <div
    id="pm-filter-menu"
    class="min-w-[450px] absolute z-20 top-[43px] right-0 bg-white border rounded-lg shadow-lg"
    @keyup="e => e.key === 'Escape' && emit('close')"
  >
    <Vueform
      ref="form$"
      v-model="state.form_values"
      size="sm"
      :columns="{
        default: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
        sm: {
          label: 4,
        },
        md: {
          label: 4,
        },
        lg: {
          label: 4,
        },
      }"
      @mounted="setInitialFilters"
    >
      <div class="col-span-12">
        <div class="text-md font-semibold text-gray-900 !py-4 !px-6">
          {{ $t('Filter activities') }}
        </div>
        <hr>
        <div class="scrollbar !max-h-[380px] !py-4 !px-6">
          <SliderElement
            name="progress"
            :label="$t('Progress')"
            :default="state.form_values.progress"
            class="mb-6 mt-4"
          />
          <SelectElement
            :presets="['underline']"
            name="type"
            :native="false"
            :can-clear="false"
            :can-deselect="false"
            :label="$t('Type')"
            :items="[
              {
                label: $t('Any'),
                value: 'any',
              },
              {
                label: $t('Activity'),
                value: 'activity',
              },
              {
                label: $t('Milestone'),
                value: 'milestone',
              },
            ]"
            class="mb-6"
          />
          <SelectElement
            :presets="['underline']"
            name="critical"
            :native="false"
            :can-clear="false"
            :can-deselect="false"
            :label="$t('Critical')"
            :items="[
              {
                label: $t('Any'),
                value: 'any',
              },
              {
                label: $t('Yes'),
                value: 'yes',
              },
              {
                label: $t('No'),
                value: 'no',
              },
            ]"
            class="mb-6"
          />
          <SelectElement
            v-if="!is_schedule_editable"
            :presets="['underline']"
            name="progress_updates"
            :native="false"
            :can-clear="false"
            :can-deselect="false"
            :label="$t('Progress updates')"
            :items="[
              {
                label: $t('Any'),
                value: '',
              },
              {
                label: $t('Manual'),
                value: 'manual',
              },
              {
                label: $t('Automatic'),
                value: 'automatic',
              },
            ]"
            class="mb-6"
          />
          <TagsElement
            v-if="active_schedule.track_resources"
            name="resources"
            :label="$t('Resources')"
            :search="true"
            :native="false"
            :close-on-select="false"
            :items="all_resources_list"
            track-by="search_uid"
            value-prop="uid"
            :presets="['underline']"
            :append-to-body="!is_fullscreen"
            class="mb-6"
          >
            <template #tag="{ option, handleTagRemove }">
              <div class="flex items-center whitespace-nowrap text-sm rounded-lg border border-gray-300 py-[3px] px-1.5 mr-1 mb-1">
                <template v-if="option.type === 'custom'">
                  <div class="flex items-center text-xs font-medium text-gray-700">
                    <div class="w-4 h-4 flex items-center justify-center bg-gray-100 font-medium text-gray-600 rounded-full mr-1.5">
                      {{ option.name.charAt(0) }}
                    </div>
                    {{ option.name }}
                  </div>
                </template>
                <template v-else-if="option.type === 'member'">
                  <HawkMembers
                    :members="option.external_id"
                    :has_avatar="true"
                    type="label"
                    size="tiny"
                  />
                </template>
                <div
                  class="rounded"
                  @mousedown.prevent="handleTagRemove(option, $event)"
                >
                  <IconHawkX class="h-4 w-4 text-gray-400 hover:text-gray-600" />
                </div>
              </div>
            </template>
            <template #option="{ option }">
              <template v-if="option.type === 'custom'">
                <div class="w-5 h-5 flex items-center justify-center bg-gray-100 text-md font-medium text-gray-600 rounded-full mr-1.5">
                  {{ option.name.charAt(0) }}
                </div>
                {{ option.name }}
              </template>
              <template v-else-if="option.type === 'member'">
                <HawkMembers :members="option.external_id" :has_avatar="true" type="label" size="xxs" />
              </template>
            </template>
          </TagsElement>
          <TagsElement
            :presets="['underline']"
            :items="status_options"
            :default="state.form_values.statuses"
            :label="$t('Status')"
            :can-clear="false"
            name="statuses"
            :tags_removable="true"
            :append-to-body="!is_fullscreen"
            class="mb-6"
          />
          <SelectElement
            :presets="['underline']"
            :items="timerange_options"
            :search="false"
            :native="false"
            :can-clear="true"
            :can-deselect="false"
            :remove-class="{
              select: {
                option: '!px-2 !min-h-[40px]',
              },
            }"
            :add-class="{
              select: {
                dropdown: '!overflow-visible',
                option: '!p-0',
              },
            }"
            autocomplete="off"
            class="mb-6"
            :label="$t('Date range')"
            name="timerange"
            :append-to-body="!is_fullscreen"
            @change="setTimerangeFilter($event)"
          >
            <template #single-label>
              <div class="w-full p-2">
                {{ date_range_text }}
              </div>
            </template>
            <template #option="{ option }">
              <div v-if="option.value !== 'custom'" class="p-2">
                {{ option.label }}
              </div>
              <div v-else class="w-full p-2" @click="onCustomClicked">
                {{ $t('Custom') }}
              </div>
            </template>
          </SelectElement>
          <template
            v-for="activity_code of activity_codes"
            :key="activity_code.uid"
          >
            <TagsElement
              :presets="['underline']"
              :items="mapFilterOptions(activity_code.values)"
              :default="state.form_values[`__activity_code_${activity_code.name}`]"
              :label="activity_code.name"
              :can-clear="false"
              :name="`__activity_code_${activity_code.name}`"
              :tags_removable="true"
              :append-to-body="!is_fullscreen"
              class="w-full mb-6 last:mb-0"
            />
          </template>
        </div>
        <div class="flex border-t justify-end w-full col-span-full px-8 py-2">
          <HawkButton
            name="cancel"
            type="plain"
            :secondary="true"
            @click="emit('close')"
          >
            {{ $t('Close') }}
          </HawkButton>
          <HawkButton
            name="save"
            type="link"
            @click="saveFilters"
          >
            {{ $t('Apply') }}
          </HawkButton>
        </div>
      </div>
    </Vueform>
  </div>
</template>
