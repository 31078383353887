<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';

// composables
import { storeToRefs } from 'pinia';
import { useCommonStore } from '~/common/stores/common.store';
import { useDocumentCrud } from '~/dms/composables/document-crud.composable';

import { useDocumentStore } from '~/dms/store/document.store.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
// stores

// components
import HawkAttach from '~/common/components/organisms/hawk-attach/hawk-attach.vue';

const props = defineProps({
  refetch_activity: {
    type: Function,
    default: null,
  },
  restricted_items: {
    type: Array,
    default: () => [],
  },
});

const $t = inject('$t');

const route = useRoute();

const document_crud = useDocumentCrud();

const common_store = useCommonStore();
const document_store = useDocumentStore();
const project_management_store = useProjectManagementStore();

const { active_task } = storeToRefs(project_management_store);

const attach_modal = useModal({
  component: HawkAttach,
  attrs: {
    onClose() {
      attach_modal.close();
    },
    is_modal: true,
    type: 'Files',
  },
});

const pm_options = computed(() => {
  return {
    query: {
      resource_uid: active_task.value.uid,
      resource_type: 'PM_ACTIVITY',
      is_root: false,
    },
    is_pm: true,
    visible_columns: ['name', 'context_menu', 'updated_at'],
    module: 'project_management',
    create_payload: {
      target_element: common_store.assets_map[route.params.asset_id]?.element,
    },
    is_drag_and_drop: true,
  };
});

async function updateActivityReference(items, type, references = []) {
  try {
    await project_management_store.update_activity_reference({
      activity: active_task.value,
      body: {
        [type]: items.map(uid => ({
          resource_id: uid,
          resource_type: 'FILE',
        })),
      },
    });
    if (references?.length) {
      await document_store.crud_documents({
        request: {
          body: {
            files: {
              update: references,
            },
          },
        },
      });
    }
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    await props.refetch_activity?.();
  }
}

function openNewFileUploadModal(flag, files = []) {
  document_crud.uploadHandler({
    flag,
    files,
    options: {
      ...pm_options.value?.create_payload,
      references: [
        {
          resource_uid: active_task.value.uid,
          resource_type: 'PM_ACTIVITY',
        },
      ],
    },
    callback: async (item) => {
      await updateActivityReference(item, 'add');
    },
  });
}

function openAttachmentModal() {
  attach_modal.patchOptions({
    attrs: {
      onClose() {
        attach_modal.close();
      },
      asset_id: route.params.asset_id,
      async on_attach(data) {
        const attachment_ids = data?.map(item => item.uid);
        const references = data?.map((item) => {
          return {
            uid: item.uid,
            references: [...item.references, {
              resource_uid: active_task.value?.uid,
              resource_type: 'PM_ACTIVITY',
            }],
          };
        });
        await updateActivityReference(attachment_ids, 'add', references);
      },
      restricted_items: props.restricted_items,
    },
  });
  attach_modal.open();
}
</script>

<template>
  <div class="flex">
    <HawkButton type="text" @click="openNewFileUploadModal()">
      <IconHawkPlus />
      {{ $t('Add file') }}
    </HawkButton>
    <HawkButton type="text" @click="openAttachmentModal()">
      <IconHawkClipboard />
      {{ $t('Choose from existing files.') }}
    </HawkButton>
  </div>
</template>
