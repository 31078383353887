<script setup>
import { cloneDeep } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useCommonStore } from '~/common/stores/common.store';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const props = defineProps({
  resource: {
    type: Object,
    required: true,
  },
  initialFormData: {
    type: Object,
    default: () => (
      {
        working_hours: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        selected_weekdays: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'],
      }
    ),
  },
});

const emit = defineEmits(['close']);

const $t = inject('$t');

const common_store = useCommonStore();
const project_management_store = useProjectManagementStore();
const { get_user_or_team_name, is_type_user } = common_store;
const { save_active_schedule } = project_management_store;
const { active_schedule } = storeToRefs(project_management_store);

const weekday_items = [
  ['Sun', 'Sunday', 'SUNDAY'],
  ['Mon', 'Monday', 'MONDAY'],
  ['Tue', 'Tuesday', 'TUESDAY'],
  ['Wed', 'Wednesday', 'WEDNESDAY'],
  ['Thu', 'Thursday', 'THURSDAY'],
  ['Fri', 'Friday', 'FRIDAY'],
  ['Sat', 'Saturday', 'SATURDAY'],
].map(day => ({
  label: $t(day[0]),
  name: day[1],
  value: day[2],
}));

const working_hours_items = [];
for (let i = 0; i < 24; i++) {
  let label = '';
  if (i === 0)
    label = `12 ${$t('AM')}`;
  else if (i === 12)
    label = `12 ${$t('PM')}`;
  else if (i < 12)
    label = `${i} ${$t('AM')}`;
  else
    label = `${i - 12} ${$t('PM')}`;

  working_hours_items.push({
    value: i,
    label,
  });
}

const state = reactive({
  is_loading: false,
  form_data: cloneDeep(props.initialFormData),
  resource_items: [],
});

const working_hours_tags = computed(() => {
  let hours = state.form_data.working_hours;
  hours = hours.sort((a, b) => a - b);
  const ranges = [];
  let start = hours[0];

  for (let i = 1; i < hours.length; i++) {
    if (hours[i] !== hours[i - 1] + 1) {
      ranges.push(
        {
          start: working_hours_items[start],
          end: working_hours_items[(hours[i - 1] + 1) % working_hours_items.length],
        },
      );
      start = hours[i];
    }
  }
  ranges.push({
    start: working_hours_items[start],
    end: working_hours_items[(hours[hours.length - 1] + 1) % working_hours_items.length],
  });
  return ranges;
});

async function onSave() {
  state.is_loading = true;
  let calendars = cloneDeep(active_schedule.value.calendars);
  const working_hours = working_hours_tags.value.reduce((acc, curr) => {
    acc.push(`${String(curr.start.value).padStart(2, '0')}:00-${String(curr.end.value).padStart(2, '0')}:00`);
    return acc;
  }, []);
  const days = weekday_items.reduce((acc, curr) => {
    acc[curr.value] = {
      is_working: state.form_data.selected_weekdays.includes(curr.value),
      working_hours,
    };
    return acc;
  }, {});
  const new_calendar = {
    id: props.resource.uid,
    name: props.resource?.name || props.resource?.external_id,
    days,
    exceptions: [],
  };
  calendars = calendars.filter(calendar => calendar.id !== new_calendar.id);
  calendars.push(new_calendar);

  const data = await save_active_schedule({
    calendars,
  });
  active_schedule.value.calendars = data.data[0].calendars;
  state.is_loading = false;
  emit('close');
}

onMounted(async () => {
  state.resource_items = active_schedule.value.resources.map((item) => {
    let label;
    if (item.type === 'member')
      label = get_user_or_team_name(item.external_id);
    else label = item.name;
    return {
      ...item,
      value: item.external_id || item.name,
      label,
    };
  });
});
</script>

<template>
  <HawkModalContainer content_class="w-[700px]">
    <Vueform
      v-model="state.form_data"
      sync
      size="sm"
      :display-errors="false"
      :display-messages="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            Resource calendar settings
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <div class="grid grid-cols-12 mb-6">
            <div class="col-span-4 text-sm font-medium text-gray-700">
              Resource
            </div>
            <div class="col-span-8">
              <template v-if="props.resource.type === 'custom'">
                <HawkBadge size="md" custom_classes="!pl-0 bg-gray-100 text-gray-100">
                  <div class="flex items-center font-medium text-gray-700">
                    <div class="w-6 h-6 flex items-center justify-center bg-gray-100 text-md font-medium text-gray-600 rounded-full">
                      {{ props.resource.name.charAt(0) }}
                    </div>
                    {{ props.resource.name }}
                  </div>
                </HawkBadge>
              </template>
              <template v-else-if="props.resource.type === 'member'">
                <template v-if="is_type_user(props.resource.external_id)">
                  <HawkMembers :members="props.resource.external_id" :has_avatar="true" type="badge" size="md" />
                </template>
                <template v-else>
                  <HawkMembers :members="props.resource.external_id" :has_avatar="true" type="badge" size="md" />
                </template>
              </template>
            </div>
          </div>
          <CheckboxgroupElement
            name="selected_weekdays"
            label="Working days"
            :items="weekday_items"
            rules="required"
            view="tabs"
            class="mb-6"
            :add-classes="{
              CheckboxgroupCheckbox: {
                container: 'h-9',
              },
            }"
          />
          <TagsElement
            name="working_hours"
            :close-on-select="false"
            :search="true"
            :items="working_hours_items"
            label="Working hours"
            input-type="search"
            autocomplete="off"
            rules="required"
            :can-clear="false"
            :hide-selected="false"
            :add-classes="{
              TagsElement: {
                select: {
                  wrapper: '!py-1',
                  options: '!flex !flex-wrap !flex-row !gap-x-[2%] !gap-y-3 !py-3 !px-[14px]',
                  option: '!py-[2px] !min-h-[24px] w-[15%] hover:!bg-primary-700 hover:!text-white !border !border-gray-300 hover:!border-primary-700 !justify-center',
                  optionSelected: '!bg-primary-600 !text-white !border-primary-600',
                  optionSelectedPointed: '!bg-primary-600 !text-white !border-primary-600',
                  optionPointed: '!bg-white',
                },
              },
            }"
            :remove-classes="{
              TagsElement: {
                select: {
                  dropdown: 'max-h-60 overflow-y-scroll',
                  tags_sm: 'form-mt-space-tags-sm',
                  options: '!p-1',
                  option: '!px-2 !min-h-[40px]',
                },
              },
            }"
            :extend-options="{
              appendToBody: true,
            }"
          >
            <template #tag="{ el$, option }">
              <div v-if="el$.data.working_hours[0] === state.form_data.working_hours[0] && el$.data.working_hours[0] === option.value" class="flex flex-wrap gap-1">
                <div
                  v-for="tag in working_hours_tags" :key="tag.start.value"
                  class="border border-gray-300 rounded-lg py-[2px] pr-1 pl-[9px] text-sm font-medium text-gray-700"
                >
                  {{ tag.start.label }} - {{ tag.end.label }}
                  <IconHawkXClose class="w-3 h-3 text-gray-400 hover:text-gray-600 inline" @click="removeTagGroup(tag)" />
                </div>
              </div>
              <div v-else />
            </template>
            <template #after-list>
              <div class="text-sm font-normal text-gray-400 ml-3 mb-2">
                {{ $t('Unselect to set up non working hours') }}
              </div>
            </template>
          </TagsElement>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <ButtonElement name="cancel" :secondary="true" @click="$emit('close')">
                {{ $t('Cancel') }}
              </ButtonElement>
              <ButtonElement name="save" class="ml-3" :loading="state.is_loading" submits>
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
