<script setup>
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { toRaw } from 'vue';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const { active_view } = storeToRefs(project_management_store);
const { set_active_date_range, set_view_dirtiness } = project_management_store;

const form$ = ref(null);

const menu_items = [
  [
    'all-time',
    'All time',
    undefined,
    undefined,
  ],
  [
    'week',
    'Week',
    dayjs().format('YYYY-MM-DD'),
    dayjs().add(1, 'week').format('YYYY-MM-DD'),
  ],
  [
    'two-weeks',
    'Two weeks',
    dayjs().format('YYYY-MM-DD'),
    dayjs().add(2, 'week').format('YYYY-MM-DD'),
  ],
  [
    'four-weeks',
    'Four weeks',
    dayjs().format('YYYY-MM-DD'),
    dayjs().add(4, 'week').format('YYYY-MM-DD'),
  ],
  [
    'six-weeks',
    'Six weeks',
    dayjs().format('YYYY-MM-DD'),
    dayjs().add(6, 'week').format('YYYY-MM-DD'),
  ],
  [
    'month',
    'Month',
    dayjs().format('YYYY-MM-DD'),
    dayjs().add(1, 'month').format('YYYY-MM-DD'),
  ],
  [
    'custom',
    'Custom',
    null,
    null,
  ],
].map(item => ({
  uid: item[0],
  label: $t(item[1]),
  value: { from: item[2], to: item[3] },
  ...(item[0] !== 'custom'
    ? {
        on_click: () => {
          set_active_date_range({ from: item[2], to: item[3] });
          set_view_dirtiness(true);
        },
      }
    : {}),
}));

const state = reactive({
  custom_date: {
    date_range: [],
  },
  date_range_saved: [
    dayjs(active_view.value.data.active_date_range.from).format('YYYY-MM-DD'),
    dayjs(active_view.value.data.active_date_range.to).format('YYYY-MM-DD'),
  ],
});

const date_range_text = computed(() => {
  const range = toRaw(active_view.value.data.active_date_range);

  if (!range.from && !range.to)
    return menu_items[0].label; // first one, All time

  const range_from = dayjs(range.from).format('YYYY-MM-DD');
  const range_to = dayjs(range.to).format('YYYY-MM-DD');

  const found = menu_items.find((item) => {
    return ((item.value.from === range_from) && (item.value.to === range_to));
  });

  return found?.label || $t('Custom');
});

onMounted(() => {
  state.custom_date.date_range = state.date_range_saved;
});

function setCustomDate() {
  const from = dayjs(state.custom_date.date_range[0]).format('YYYY-MM-DD');
  const to = dayjs(state.custom_date.date_range[1]).format('YYYY-MM-DD');
  state.date_range_saved = [from, to];
  set_active_date_range({ from, to });
  set_view_dirtiness(true);
}
</script>

<template>
  <hawk-menu
    :items="menu_items"
    additional_dropdown_classes="right-full !-top-0 mr-4 !mt-0 !bottom-auto"
    additional_trigger_classes="!ring-0"
    additional_item_classes="!p-0"
    position="bottom-left"
  >
    <template #trigger>
      <div class="flex items-center h-9 hover:bg-gray-50 rounded-lg py-2 px-3 -ml-3 w-[232px]">
        <div class="text-sm text-ellipsis overflow-hidden relative whitespace-nowrap">
          <span class="text-gray-500">{{ $t('Time range') }}: </span>
          <span class="font-medium">{{ date_range_text }}</span>
        </div>
        <IconHawkChevronRight class="ml-auto" />
      </div>
    </template>
    <template #item="{ item }">
      <div v-if="item.uid !== 'custom'" class="flex items-center w-full px-3 h py-3">
        {{ item.label }}
      </div>
      <hawk-menu
        v-else
        additional_dropdown_classes="right-full !top-0 mr-1 !mt-0 !bottom-auto"
        additional_trigger_classes="!ring-0 w-full"
        position="bottom-left"
      >
        <template #trigger>
          <div class="flex items-center w-40 px-3 h py-3">
            <span class="whitespace-nowrap">
              {{ item.label }}
            </span>
            <IconHawkChevronRight class="ml-auto" />
          </div>
        </template>
        <template #content>
          <div class="p-3 w-[380px]" @click.stop>
            <Vueform ref="form$" v-model="state.custom_date" sync size="sm">
              <div class="col col-span-12 flex justify-between">
                <div class="font-medium mr-2 flex items-center">
                  {{ $t('Date Range') }}:
                </div>
                <DateTimeElement
                  name="date_range"
                  class="w-64"
                  :options="{
                    range: true,
                    teleport: false,
                    format: 'dd/MM/yyyy',
                  }"
                />
              </div>
            </Vueform>
          </div>
        </template>
        <template #footer="{ close }">
          <Vueform size="sm" class="p-2">
            <div class="flex justify-end w-full col-span-full">
              <ButtonElement
                name="cancel"
                :secondary="true"
                @click="close()"
              >
                {{ $t('Cancel') }}
              </ButtonElement>
              <ButtonElement
                name="apply"
                class="ml-3"
                :disabled="!state.custom_date?.date_range?.length"
                @click="setCustomDate();close();"
              >
                {{ $t('Apply') }}
              </ButtonElement>
            </div>
          </Vueform>
        </template>
      </hawk-menu>
    </template>
  </hawk-menu>
</template>
