import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import minMax from 'dayjs/plugin/minMax';
import { uniq } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { computed, inject, watch } from 'vue';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useHelpers } from '~/project-management/composables/pm-helpers.composable';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

dayjs.extend(minMax);
dayjs.extend(isSameOrBefore);

export function useResources() {
  const $t = inject('$t');

  const common_store = useCommonStore();
  const project_management_store = useProjectManagementStore();

  const { computeResourceCellClass, computeTotalUnitsRequired } = useHelpers();

  // TODO: build an array computed property that has uids and name, label etc. that is needed by gantt for each resource
  const { get_user_or_team_name } = common_store;
  const { $g, active_schedule, active_view, flags, resource_mode } = storeToRefs(project_management_store);

  const resources = computed(() => [
    ...(active_schedule.value?.resources || []),
  ]);

  function setupResources() {
    $g.value.templates.resource_cell_class = function (start_date, end_date, resource, tasks) {
      return computeResourceCellClass(start_date, end_date, resource, tasks).css;
    };

    $g.value.templates.resource_cell_value = function (start_date, end_date, resource, tasks) {
      const total_units_required = computeTotalUnitsRequired(start_date, end_date, resource, tasks);
      if (!$g.value.isWorkTime(start_date) && active_view.value.data.zoom_level === 4)
        return;
      if (resource_mode.value === 'hours') {
        if (tasks.length && resource.cost_type === 'per_hour')
          return `<div>${total_units_required}</div>`;
        else
          return '<div>-</div>';
      }

      else if (resource_mode.value === 'tasks') {
        return `<div>${tasks.length}</div>`;
      }
    };

    $g.value.config.resource_store = 'resource';
    $g.value.config.resource_property = 'resources';

    const resources_store = $g.value.createDatastore({
      name: $g.value.config.resource_store,
      type: 'treeDatastore',
      initItem(item) {
        item.parent = item.parent || $g.value.config.root_id;
        item[$g.value.config.resource_property] = item.parent;
        item.open = true;
        if (item.id === 'unassigned')
          item.open = false;
        return item;
      },
    });

    resources_store.attachEvent('onParse', () => {
      const people = [];
      resources_store.eachItem((res) => {
        if (!resources_store.hasChild(res.id)) {
          const copy = $g.value.copy(res);
          copy.key = res.id;
          copy.label = res.text;
          people.push(copy);
        }
      });
      $g.value.updateCollection('people', people);
    });

    watch(() => [
      active_schedule.value?.resource_assignments,
      active_schedule.value?.resources,
      flags.value.resources_section_reload_count,
      active_view.value?.data?.layout?.resource_grid,
      active_view.value?.data?.layout?.resource_chart,
      active_schedule.value?.track_resources,
    ], () => {
      if (!active_schedule.value || active_view.value?.data?.layout?.resource_grid === false || active_view.value?.data?.layout?.resource_chart === false || active_schedule.value?.track_resources === false)
        return;
      let resource_uids = active_schedule.value?.resource_assignments?.map?.(assignment => assignment.resource).filter(Boolean) || [];
      resource_uids = uniq(resource_uids);

      const current_resources = resources.value.map((res) => {
        if (resource_uids.includes(res.uid)) {
          return {
            ...res,
            parent: 'assigned',
            id: res.uid,
            text: res.name || get_user_or_team_name(res.external_id),
          };
        }
        else {
          return {
            ...res,
            parent: 'unassigned',
            id: res.uid,
            text: res.name || get_user_or_team_name(res.external_id),
          };
        }
      });

      current_resources.unshift(
        { id: 'assigned', text: $t('Assigned'), parent: null },
        { id: 'unassigned', text: $t('Unassigned'), parent: null },
      );

      try {
        resources_store.clearAll();
        resources_store.parse(current_resources);
        $g.value.render();
      }
      catch (error) {
        logger.error(error);
        window.SH_DEBUG && logger.warn('[DEBUG] pm-resources.composable.js::137\nresources_store clearAll and parse failed, but this may not be an issue');
      }
    }, { immediate: true });
  }

  return {
    setupResources,
  };
}
