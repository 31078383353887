<script setup>
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const $t = inject('$t');

const project_management_store = useProjectManagementStore();

const { active_view, is_fullscreen, tippy_target } = storeToRefs(project_management_store);
const zoom_level = computed(() => active_view.value.data.zoom_level);

const { set_view_dirtiness, modify_config } = project_management_store;

const is_group_by_length_zero = computed(() => active_view.value.data.group_by.length !== 0);

function onZoomOutClicked() {
  if (zoom_level.value > 0) {
    modify_config({ key: 'zoom_level', value: zoom_level.value - 1 });
    set_view_dirtiness(true);
  }
}

function onZoomInClicked() {
  if (zoom_level.value < 4) {
    modify_config({ key: 'zoom_level', value: zoom_level.value + 1 });
    set_view_dirtiness(true);
  }
}

function onFullscreenClicked() {
  if (is_fullscreen.value) {
    document.exitFullscreen();
  }
  else {
    const element = document.getElementById('pm-fullscreen-container');
    if (element.requestFullscreen)
      element.requestFullscreen();

    else if (element.mozRequestFullScreen)
      element.mozRequestFullScreen();

    else if (element.webkitRequestFullscreen)
      element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);

    else if (element.msRequestFullscreen)
      element.msRequestFullscreen();
  }
}
</script>

<template>
  <div class="flex items-center h-9">
    <HawkButton
      v-tippy="{
        content: $t('Zoom out'),
        placement: 'bottom',
        appendTo: tippy_target,
      }"
      icon
      type="text"
      :disabled="is_group_by_length_zero"
      :class="{ 'cursor-not-allowed': is_group_by_length_zero, 'pointer-events-none opacity-40': zoom_level <= 0 }"
      @click="onZoomOutClicked"
    >
      <IconHawkMinus class="w-4 h-4 text-gray-600" />
    </HawkButton>
    <HawkButton
      v-tippy="{
        content: $t('Zoom in'),
        placement: 'bottom',
        appendTo: tippy_target,
      }"
      icon
      type="text"
      :disabled="is_group_by_length_zero"
      :class="{ 'cursor-not-allowed': is_group_by_length_zero, 'pointer-events-none opacity-40': zoom_level >= 4 }"
      @click="onZoomInClicked"
    >
      <IconHawkPlus class="w-4 h-4 text-gray-600" />
    </HawkButton>
    <HawkButton
      v-tippy="{
        content: $t('Fullscreen mode'),
        placement: 'bottom',
        appendTo: tippy_target,
      }"
      icon
      type="text"
      @click="onFullscreenClicked"
    >
      <IconHawkFullScreen class="w-4 h-4 text-gray-600" />
    </HawkButton>
  </div>
</template>
