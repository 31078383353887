<script setup>
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { useCommonStore } from '~/common/stores/common.store';
import { useDocumentCrud } from '~/dms/composables/document-crud.composable';
import { useFormsStore } from '~/forms/store/forms.store.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import { useTasksStore } from '~/tasks/store/tasks.store.js';

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['add-reference']);

const route = useRoute();
const router = useRouter();

const document_crud = useDocumentCrud();

const common_store = useCommonStore();
const tasks_store = useTasksStore();
const forms_store = useFormsStore();
const project_management_store = useProjectManagementStore();
const { active_task, references, tippy_target } = storeToRefs(project_management_store);
const { refetch_activity, update_activity_reference, set_references } = project_management_store;

const $t = inject('$t');
const $date = inject('$date');
const $services = inject('$services');

const { open: openDeletePopup, close: closeDeletePopup, patchOptions: patchDeleteOptions } = useModal({
  component: HawkDeletePopup,
});

const state = reactive({
  are_references_open: true,
  loading_uids: [],
});

function getStatusBackgroundColor(item) {
  if (item.type === 'TASK')
    return item.bg_color;
  else if (item.color)
    return `${item.color}1a`;
  else return '#6670851a';
}

async function triggerRefetchActivity() {
  await refetch_activity(active_task.value.uid);
  await set_references($t);
}

async function detachReference(references, type, type_of_update = 'remove') {
  const reference_uids = Array.isArray(references) ? references : [references];
  try {
    state.loading_uids.push(...reference_uids);
    const service_name = {
      FILE: 'documents',
      TASK: 'tasks',
      FORM: 'forms',
    };

    const type_of_update_pm = type_of_update === 'add' ? 'add' : 'delete';
    await update_activity_reference({
      activity: active_task.value,
      body: {
        [type_of_update_pm]: reference_uids.map(uid => ({
          resource_id: uid,
          resource_type: type,
        })),
      },
    });
    if (service_name[type] === 'forms') {
      await $services[service_name[type]].post({
        body: {
          [service_name[type]]: {
            update: reference_uids.map(
              uid => ({
                references: {
                  [type_of_update]: [
                    {
                      resource_id: active_task.value.uid,
                      resource_type: 'PM_ACTIVITY',
                    },
                  ],
                },
                uid,
              }),
            ),
          },
        },
      });
    }
    else if (service_name[type] === 'tasks') {
      await $services[service_name[type]].patch({
        body: {
          [service_name[type]]: reference_uids.map(
            uid => ({
              references: {
                [type_of_update]: [
                  {
                    resource_id: active_task.value.uid,
                    resource_type: 'PM_ACTIVITY',
                  },
                ],
              },
              uid,
            }),
          ),
        },
      });
    }
    else if (service_name[type] === 'documents') {
      await $services[service_name[type]].post({
        body: {
          files: reference_uids.map(
            uid => ({
              references: {
                [type_of_update]: [
                  {
                    resource_uid: active_task.value.uid,
                    resource_type: 'PM_ACTIVITY',
                  },
                ],
              },
              uid,
            }),
          ),
        },
      });
    }
  }
  catch (error) {
    logger.error(error.message);
  }
  finally {
    await refetch_activity(active_task.value.uid);
    await set_references($t);
    state.loading_uids = state.loading_uids.filter(uid => reference_uids.includes(uid));
  }
}

function deleteReference(item) {
  if (item.type === 'TASK' || item.type === 'FORM') {
    patchDeleteOptions(
      {
        attrs: {
          header: $t('Delete item(s)'),
          content: `${$t('Are you sure you want to delete')} ${item.name || ''}? ${$t('This action cannot be undone')}.`,
          onClose() {
            closeDeletePopup();
          },
          confirm: async () => {
            try {
              if (item.type === 'TASK')
                await tasks_store.remove_tasks([item.uid]);
              else if (item.type === 'FORM')
                await forms_store.remove_forms([item.uid]);
              await triggerRefetchActivity();
              closeDeletePopup();
            }
            catch (error) {
              $toast({
                title: 'Something went wrong',
                text: 'Please try again',
                type: 'error',
              });
              logger.error(error);
            }
          },
        },
      },
    );
    openDeletePopup();
  }
  else if (item.type === 'FILE') {
    document_crud.itemDelete({
      item: {
        ...item,
        type: 'file',
      },
      update_hierarchy: false,
      callback: async () => {
        await refetch_activity(active_task.value.uid);
        await set_references($t);
      },
    });
  }
}

function viewDetailsHandler(item) {
  if (item.type === 'TASK') {
    router.push({
      ...route,
      query: {
        task: btoa(JSON.stringify({
          id: item.uid,
        })),
      },
    });
  }

  else if (item.type === 'FORM') {
    router.push({
      query: {
        ...route.query,
        form: btoa(JSON.stringify({ form_uid: item.uid })),
      },
    });
  }
  else if (item.type === 'FILE') {
    const route_data = router.resolve({
      name: 'files-documents-all-files',
      params: {
        asset_id: route.params.asset_id,
      },
      query: {
        document: item.uid,
        pm: true,
      },
    });
    window.open(route_data.href, '_blank');
  }
}
</script>

<template>
  <div v-if="references.length" class="py-4">
    <div class="flex items-center gap-2">
      <div
        class="cursor-pointer flex items-center"
        @click="state.are_references_open = !state.are_references_open"
      >
        <IconHawkChevronDown v-if="state.are_references_open" class="inline" />
        <IconHawkChevronRight v-else class="inline" />
        <span class="text-sm font-semibold text-gray-900 ml-1">
          {{ $t('References') }}
        </span>
      </div>
      <div
        class="w-5 h-5 bg-primary-50 hover:bg-primary-100 flex items-center justify-center rounded-full cursor-pointer"
        @click="emit('add-reference')"
      >
        <IconHawkPlus class="w-3 h-3 text-primary-700" />
      </div>
    </div>
    <div v-if="state.are_references_open" class="mt-2 ml-4">
      <PmListComponent :items="references" height-class="h-[65px]">
        <template #left_section="{ item }">
          <HawkSkeleton v-if="props.isLoading || state.loading_uids.includes(item.uid)" class="w-[400px]" />
          <template v-else>
            <div
              class="text-sm font-medium text-gray-900 cursor-pointer hover:text-primary-600"
              @click="viewDetailsHandler(item)"
            >
              {{ item.name }}
            </div>
            <HawkBadge v-if="item.category" class="flex items-center">
              {{ common_store.get_category(item.category)?.name }}
            </HawkBadge>
          </template>
        </template>
        <template #right_section="{ item }">
          <template v-if="!props.isLoading && !state.loading_uids.includes(item.uid)">
            <div class="flex items-center gap-3">
              <IconHawkLinkBrokenOne
                v-tippy="{
                  content: $t('Detach'),
                  appendTo: tippy_target,
                }"
                class="text-gray-600 cursor-pointer outline-0 invisible group-hover:visible"
                @click="detachReference(item.uid, item.type)"
              />
              <IconHawkTrashThree
                v-tippy="{
                  content: $t('Delete'),
                  appendTo: tippy_target,
                }"
                class="text-gray-600 cursor-pointer outline-0 invisible group-hover:visible"
                @click="deleteReference(item)"
              />
              <div class="flex items-center gap-3 group-hover:hidden">
                <HawkBadge
                  v-if="item.status"
                  class="flex"
                  :style="`
                  color: ${item.color};
                  background-color: ${getStatusBackgroundColor(item)}
                `"
                >
                  {{ item.status }}
                </HawkBadge>
                <span
                  class="text-xs font-normal text-gray-700"
                  :class="{
                    'text-error-700': dayjs(item.date).isBefore(dayjs()),
                  }"
                >
                  {{ $date(item.date, 'Do MMM, YYYY') }}
                </span>
              </div>
            </div>
            <HawkMembers
              v-if="item.assignees.length"
              :members="item.assignees"
              :has_avatar="true"
              size="xs"
              type="group"
              class="w-full flex justify-end group-hover:hidden"
            />
          </template>
        </template>
      </PmListComponent>
    </div>
  </div>
</template>
