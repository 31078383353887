<script setup>
// --------------------------------- Imports -------------------------------- //
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

// ---------------------------------- Props --------------------------------- //

// ---------------------------------- Emits --------------------------------- //

// ---------------------------- Injects/Provides ---------------------------- //
const $t = inject('$t');
const project_management_store = useProjectManagementStore();

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //
const { $g, active_view, tippy_target } = storeToRefs(project_management_store);
const { modify_filter, set_view_dirtiness } = project_management_store;

// ------------------- Variables - Local - consts and lets ------------------ //

// ------------------------ Variables - Local - refs ------------------------ //

// ---------------------- Variables - Local - reactives --------------------- //
const state = reactive({
  is_quick_filter_menu_open: false,
  selected_quick_filter: 'all_activities',
});

// --------------------------- Computed properties -------------------------- //
const filters = computed(() => active_view.value.data.filters);

const is_milestone_and_other_active = computed(() => {
  const filters = active_view.value.data.filters;
  const date_range = $g.value?.getState();

  if (!filters.milestone)
    return false;
  if (filters.critical || filters.assigned_to_me || filters.overdue)
    return true;
  if (JSON.stringify(filters.progress) !== JSON.stringify([0, 100]))
    return true;
  if (
    filters.date_range
    && (JSON.stringify(filters.date_range) !== JSON.stringify([null, null])
      && JSON.stringify(filters.date_range) !== JSON.stringify([date_range.min_date, date_range.max_date])
    )
  ) {
    return true;
  }
  for (const key of Object.keys(filters)) {
    if (key !== 'milestone'
      && key !== 'critical'
      && key !== 'assigned_to_me'
      && key !== 'overdue'
      && key !== 'progress'
      && key !== 'date_range'
      && key !== 'priorities'
      && filters[key]?.length
    ) {
      return true;
    }
  }

  return false;
});

const are_quick_filters_disabled = computed(() => {
  return is_milestone_and_other_active.value;
});

const quick_filter_items = computed(() => {
  return [
    {
      label: $t('All activities'),
      uid: 'all_activities',
      on_click: () => {
        state.selected_quick_filter = 'all_activities';
        changeFilterState();
      },
    },
    {
      label: $t('Milestones'),
      uid: 'milestone',
      on_click: () => {
        state.selected_quick_filter = 'milestone';
        changeFilterState();
      },
    },
    {
      label: $t('Assigned to me'),
      uid: 'assigned_to_me',
      on_click: () => {
        state.selected_quick_filter = 'assigned_to_me';
        changeFilterState();
      },
    },
    {
      label: $t('Overdue'),
      uid: 'overdue',
      on_click: () => {
        state.selected_quick_filter = 'overdue';
        changeFilterState();
      },
    },
  ];
});

const active_item = computed(() => {
  return quick_filter_items.value.find(item => item.uid === state.selected_quick_filter);
});

// -------------------------------- Functions ------------------------------- //
function changeFilterState() {
  const payload = {
    ...active_view.value.data.filters,
    milestone: false,
    assigned_to_me: false,
    overdue: false,
  };
  if (state.selected_quick_filter !== 'all_activities')
    payload[state.selected_quick_filter] = true;
  modify_filter(payload);
  set_view_dirtiness(true);
}

// -------------------------------- Watchers -------------------------------- //
watch(() => [filters.value, active_view.value.data.filters.milestone], (newVal, oldVal) => {
  if (active_view.value.data.filters.milestone) {
    if (!is_milestone_and_other_active.value)
      state.selected_quick_filter = 'milestone';
  }

  if (newVal[1] !== oldVal[1] && !newVal[1] && state.selected_quick_filter === 'milestone')
    state.selected_quick_filter = 'all_activities';
});

// ----------------------------- Lifecycle Hooks ---------------------------- //
onMounted(() => {
  if (active_view.value.data.filters.milestone) {
    if (!is_milestone_and_other_active.value)
      state.selected_quick_filter = 'milestone';
  }

  if (active_view.value.data.filters.assigned_to_me)
    state.selected_quick_filter = 'assigned_to_me';

  if (active_view.value.data.filters.overdue)
    state.selected_quick_filter = 'overdue';

  if (!active_view.value.data.filters.milestone && state.selected_quick_filter === 'milestone')
    state.selected_quick_filter = 'all_activities';
});
</script>

<template>
  <HawkMenu
    :items="quick_filter_items"
    :active_item="active_item.uid"
    additional_trigger_classes="!ring-0 !border-0"
    class="h-8 mx-3 flex items-center justify-center"
    :class="{
      '!cursor-not-allowed': are_quick_filters_disabled,
      '!cursor-default': !(are_quick_filters_disabled),
    }"
    :disabled="are_quick_filters_disabled"
    @open="state.is_quick_filter_menu_open = true"
    @close="state.is_quick_filter_menu_open = false"
  >
    <template #trigger>
      <div
        v-tippy="{
          content: $t('Quick filters'),
          placement: 'bottom',
          appendTo: tippy_target,
        }" class="flex items-center gap-1"
      >
        <div v-if="are_quick_filters_disabled" class="text-xs font-medium text-gray-900">
          Multiple filters active
        </div>
        <div v-else class="text-xs font-medium text-gray-900">
          {{ active_item.label }}
        </div>
        <IconHawkChevronUp v-if="state.is_quick_filter_menu_open" class="w-4 h-4 inline" />
        <IconHawkChevronDown v-else class="w-4 h-4 inline" />
      </div>
    </template>
  </HawkMenu>
</template>
