<script setup>
import dayjs from 'dayjs';
import { keyBy } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { RRule } from 'rrule';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const emit = defineEmits(['close']);

const project_management_store = useProjectManagementStore();
const auth_store = useAuthStore();

const { configure_auto_sync } = project_management_store;

const { active_schedule } = storeToRefs(project_management_store);

const $t = inject('$t');
const $toast = inject('$toast');

const state = reactive({
  is_button_loading: false,
  form_data: null,
  current_timezone: null,
});

const form$ = ref(null);

const frequency_map = {
  Daily: $t('days'),
  Weekly: $t('weeks'),
};

const frequency_items = [
  'Daily',
  'Weekly',
].map(item => ({
  value: item,
  label: $t(item),
}));

const am_pm_items = [
  'AM',
  'PM',
].map(item => ({
  value: item,
  label: $t(item),
}));

const weekday_items = [
  ['Sun', 'Sunday', 6],
  ['Mon', 'Monday', 0],
  ['Tue', 'Tuesday', 1],
  ['Wed', 'Wednesday', 2],
  ['Thu', 'Thursday', 3],
  ['Fri', 'Friday', 4],
  ['Sat', 'Saturday', 5],
].map(day => ({
  label: $t(day[0]),
  name: day[1],
  value: day[2],
}));

const hour_list = Array.from({ length: 12 }, (_, i) => i + 1)
  .map(hour => String(hour).padStart(2, '0'));

const minute_list = Array.from({ length: 60 }, (_, i) => i)
  .map(minute => String(minute).padStart(2, '0'));

function onFormMounted(el$) {
  if (active_schedule.value.sync_schedule?.rrule) {
    // eslint-disable-next-line new-cap
    const { options: existingRules } = new RRule.fromString(active_schedule.value.sync_schedule.rrule);
    let data = {};
    data.interval = existingRules.interval;
    switch (existingRules.freq) {
      case 2:
        data.recurrence_frequency = 'Weekly';
        data.selected_weekdays = existingRules.byweekday;
        break;
      case 3:
        data.recurrence_frequency = 'Daily';
        break;
    }
    data.sync_toggler = active_schedule.value.sync_schedule.active;
    state.current_timezone = existingRules?.tzid;
    data = {
      ...data,
      ...convertTo12HourFormat(existingRules.byhour[0], existingRules.byminute[0]),
    };
    el$.load(data, true);
  }
  else {
    el$.load({
      sync_toggler: false,
      recurrence_frequency: 'Daily',
      interval: 1,
      selected_weekdays: [],
      hour: '12',
      minute: '00',
      am_pm: 'AM',
    }, true);
  }
}

function calculateWeekRule() {
  const rule = [];
  const value_name_map = keyBy(weekday_items, 'value');
  state.form_data.selected_weekdays.forEach((day_number) => {
    rule.push(RRule[value_name_map[day_number].label.slice(0, 2).toUpperCase()]);
  });
  return rule;
}

function convertTo24HourFormat() {
  const hour = Number(state.form_data.hour);
  const am_pm = state.form_data.am_pm;
  if (am_pm === 'PM' && hour !== 12)
    return hour + 12;
  if (am_pm === 'AM' && hour === 12)
    return hour - 12;
  return hour;
}

function convertTo12HourFormat(hour, minute) {
  const is_am = hour < 12;
  let hour_string = hour % 12 || 12;
  hour_string = hour_string < 10 ? `0${hour_string}` : `${hour_string}`;
  const minute_string = minute < 10 ? `0${minute}` : `${minute}`;
  const am_pm = is_am ? 'AM' : 'PM';
  return { hour: hour_string, minute: minute_string, am_pm };
}

async function onSave() {
  state.is_button_loading = true;
  await form$.value.validate();

  if (form$.value.hasErrors) {
    state.is_button_loading = false;
    return;
  }

  const data = state.form_data;
  const rrule_arg_object = {};

  if (data.sync_toggler) {
    if (data.recurrence_frequency === 'Daily') {
      rrule_arg_object.freq = RRule.DAILY;
    }
    else if (data.recurrence_frequency === 'Weekly') {
      rrule_arg_object.freq = RRule.WEEKLY;
      rrule_arg_object.byweekday = calculateWeekRule();
    }
    rrule_arg_object.dtstart = new Date();
    rrule_arg_object.interval = data.interval;
    rrule_arg_object.byhour = convertTo24HourFormat();
    rrule_arg_object.byminute = Number(data.minute);
    rrule_arg_object.tzid = auth_store.logged_in_user_details?.timezone || dayjs.tz.guess();
  }

  try {
    await configure_auto_sync({
      body: {
        rrule: data.sync_toggler ? new RRule(rrule_arg_object).toString() : undefined,
        active: data.sync_toggler,
      },
    });
  }
  catch (error) {
    $toast({
      title: error?.data?.title || $t('Something went wrong'),
      text: error?.data?.description || $t('Please try again'),
      type: 'error',
    });
  }

  state.is_button_loading = false;
  emit('close');
}
</script>

<template>
  <HawkModalContainer id="configure-auto-sync-modal">
    <Vueform
      ref="form$"
      v-model="state.form_data"
      size="sm"
      class="max-w-[600px]"
      :columns="{
        default: { container: 12, label: 3, wrapper: 12 },
        sm: { container: 12, label: 3, wrapper: 12 },
        md: { container: 12, label: 3, wrapper: 12 },
      }"
      :display-errors="false"
      :endpoint="onSave"
      @mounted="onFormMounted"
      @keydown.enter.prevent="onSave"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="flex flex-col justify-start text-gray-900">
              {{ $t("Configure auto sync") }}
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <ToggleElement name="sync_toggler" :label="$t('Enable sync')" class="h-9 mb-6" />
          <SelectElement
            name="recurrence_frequency"
            :disabled="!form$?.data?.sync_toggler"
            :label="$t('Frequency')"
            :items="frequency_items"
            :native="false"
            :can-clear="false"
            :can-deselect="false"
            default="Daily"
            class="h-9 mb-6"
            :rules="['required']"
            append-to="#configure-auto-sync-modal"
          />
          <TextElement
            class="h-9 mb-6"
            name="interval"
            input-type="number"
            :disabled="!form$?.data?.sync_toggler"
            :label="$t('Every')"
            :rules="['min:1', 'required']"
          >
            <template #addon-after>
              {{ frequency_map?.[state.form_data?.recurrence_frequency] ?? '' }}
            </template>
          </TextElement>
          <CheckboxgroupElement
            class="h-9 mb-6"
            name="selected_weekdays"
            view="tabs"
            :disabled="!form$?.data?.sync_toggler"
            :label="$t('On days')"
            :items="weekday_items"
            :conditions="[['recurrence_frequency', 'Weekly']]"
          />
          <div class="grid grid-cols-12 col-span-12">
            <div class="col-span-3 text-gray-700 text-sm font-medium">
              {{ $t('Time') }}
              <IconHawkInfoCircle
                v-tippy="{
                  content: $t('Choose the time in your local timezone to trigger the sync'),
                  placement: 'bottom',
                }"
                class="inline cursor-pointer h-4 w-4 outline-0"
              />
            </div>
            <div class="col-span-9 flex flex-col">
              <div class="col-span-9 flex justify-between">
                <SelectElement
                  name="hour"
                  :disabled="!form$?.data?.sync_toggler"
                  :placeholder="$t('Hour')"
                  :items="hour_list"
                  class="w-[125px]"
                  :native="false"
                  :can-clear="false"
                  :can-deselect="false"
                  :rules="['required']"
                  append-to="#configure-auto-sync-modal"
                />
                <SelectElement
                  name="minute"
                  :disabled="!form$?.data?.sync_toggler"
                  :placeholder="$t('Minute')"
                  :items="minute_list"
                  class="w-[125px]"
                  :native="false"
                  :can-clear="false"
                  :can-deselect="false"
                  :rules="['required']"
                  append-to="#configure-auto-sync-modal"
                />
                <SelectElement
                  name="am_pm"
                  placeholder="AM/PM"
                  :disabled="!form$?.data?.sync_toggler"
                  :items="am_pm_items"
                  class="w-[125px]"
                  :native="false"
                  :can-clear="false"
                  :can-deselect="false"
                  :rules="['required']"
                  append-to="#configure-auto-sync-modal"
                />
              </div>
              <div v-if="state.current_timezone" class="text-xs mt-2">
                {{ $t('Timezone') }}:
                <span class="font-medium">{{ state.current_timezone }}</span>
              </div>
            </div>
          </div>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <ButtonElement
                name="cancel"
                class="mr-3"
                :secondary="true"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </ButtonElement>
              <ButtonElement
                name="save"
                :loading="state.is_button_loading"
                :submits="true"
              >
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
