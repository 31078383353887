<script setup>
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const props = defineProps({
  memberUids: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['close', 'save']);

const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const { is_fullscreen } = storeToRefs(project_management_store);

const state = reactive({
  form_data: {},
  is_saving: false,
});

function onSave() {
  state.is_saving = true;
  emit('save', state.form_data);
}
</script>

<template>
  <HawkModalContainer
    :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body' }"
  >
    <Vueform
      v-model="state.form_data"
      sync
      size="sm"
      :display-errors="false"
      :display-messages="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <HawkModalContent>
          <div class="w-full flex flex-col gap-3">
            <div class="flex justify-between">
              <HawkFeaturedIcon theme="light-circle-outline" size="lg">
                <IconHawkAlertTriangle />
              </HawkFeaturedIcon>
            </div>
            <div>
              <div class="text-lg font-semibold text-gray-900">
                {{ $t('Do you want to remove activity trackings?') }}
              </div>
              <div class="text-sm font-normal text-gray-600">
                {{ $t('You are looking to remove access for the following users. You can remove activity tracking of any/all users. The trackings associated with a resource will be removed across all activities.') }}
              </div>
            </div>
            <div class="flex justify-between">
              <div class="text-sm font-semibold text-gray-700">
                {{ $t('Users') }}
              </div>
              <div class="text-sm font-semibold text-gray-700">
                {{ $t('Remove tracking') }}
              </div>
            </div>
            <div v-for="member_uid in props.memberUids" :key="member_uid" class="flex justify-between">
              <HawkMembers :members="member_uid" type="label" size="xs" />
              <ToggleElement :name="member_uid" />
            </div>
          </div>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <ButtonElement
                class="mr-4"
                :secondary="true"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </ButtonElement>
              <ButtonElement
                name="save"
                :loading="state.is_saving"
                submits
              >
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
