<script setup>
import { getFileIcon } from '~/common/utils/pretty-file-icons.util.js';

const props = defineProps({
  fileName: {
    type: String,
    default: '',
  },
  altText: {
    type: String,
    default: '',
  },
  iconClasses: {
    type: String,
    default: 'w-8 h-8',
  },
});

const alternate_text = computed(() => {
  return props.altText || props.fileName || '';
});

const imgUrl = new URL(`/assets/file-type-icons/${getFileIcon(props.fileName)}.svg`, import.meta.url).href;
</script>

<template>
  <img
    :src="imgUrl"
    :alt="alternate_text"
    :class="props.iconClasses"
  >
</template>
