<script setup>
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const props = defineProps({
  existingItems: {
    type: Array,
    default: () => [],
  },
  prefillData: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['close', 'save']);

const $t = inject('$t');
const $toast = inject('$toast');

const project_management_store = useProjectManagementStore();
const { create_user_mapping } = project_management_store;
const { is_fullscreen } = storeToRefs(project_management_store);

const state = reactive({
  form_data: props.prefillData,
  is_saving: false,
});

async function onSave() {
  state.is_saving = true;
  const mapped_resource = await create_user_mapping({
    [props.prefillData.uid]: state.form_data.member.uid,
  });
  emit('save', props.prefillData.uid, ...mapped_resource);
  state.is_saving = false;
  $toast({
    type: 'success',
    title: $t('Resource converted successfully'),
    text: $t('resource-converted-description'),
    timeout: 4000,
  });
}
</script>

<template>
  <HawkModalContainer
    id="pm-user-mapping-modal-container"
    :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body' }"
  >
    <Vueform
      v-model="state.form_data"
      sync
      size="sm"
      :display-errors="false"
      :display-messages="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            {{ $t('Convert to member') }}
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <div
            class="flex gap-[52px] text-sm font-medium text-gray-700 mb-1"
          >
            <div class="w-2/5">
              {{ $t('Custom resource') }}
            </div>
            <div class="w-3/5">
              {{ $t('Member') }}
            </div>
          </div>
          <div class="grid grid-cols-11">
            <TextElement
              name="name"
              :disabled="true"
              class="!col-span-4"
            />
            <div class="col-span-1 flex items-center justify-center">
              <IconHawkArrowRight />
            </div>
            <HawkAssigneeInput
              :key="state.assignee_key"
              :options="{
                name: 'member',
                canClear: false,
                rules: 'required',
                placeholder: $t('Select member'),
                existing_users: props.existingItems,
                existing_teams: props.existingItems,
                size: 'sm',
                has_teams: true,
                create: true,
                appendNewOption: true,
                appendTo: '#pm-user-mapping-modal-container',
              }"
              class="!col-span-6"
            >
              <template #option="{ option, uid }">
                <div v-if="!state.search" />
                <div class="absolute left-0 flex items-center h-full pl-3">
                  <div
                    v-if="option.__CREATE__"
                    class="flex gap-1 text-sm font-semibold text-gray-700 break-words rounded-lg cursor-pointer hover:bg-gray-50"
                  >
                    <IconHawkPlus />{{ $t('Add') }} "{{ option.name }}"
                  </div>
                  <div
                    v-else-if="option.name === option.uid"
                    class="flex gap-1 text-sm font-semibold text-gray-700 break-words rounded-lg cursor-pointer hover:bg-gray-50"
                  >
                    {{ option.name }}
                  </div>
                  <HawkMembers
                    v-else
                    :members="uid"
                    size="xs"
                    type="label"
                  />
                </div>
              </template>
              <template #single-label="{ value, uid }">
                <div v-if="value.name === value.uid" class="absolute left-0 flex items-center h-full pl-3">
                  <div
                    class="flex gap-1 text-sm font-semibold text-gray-700 break-words rounded-lg"
                  >
                    {{ value.name }}
                  </div>
                </div>
                <div v-else class="absolute left-0 flex items-center h-full pl-3">
                  <HawkMembers
                    :members="uid"
                    size="xs"
                    type="label"
                  />
                </div>
              </template>
            </HawkAssigneeInput>
          </div>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <ButtonElement
                class="mr-4"
                :secondary="true"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </ButtonElement>
              <ButtonElement
                name="save"
                submits
              >
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
