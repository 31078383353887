<script setup>
import { storeToRefs } from 'pinia';
import { markRaw } from 'vue';
import { useRoute } from 'vue-router';
import { useCommonStore } from '~/common/stores/common.store';
import PmNewForm from '~/forms/components/new-form/pm-new-form.vue';
import PmReferencesWidget from '~/project-management/components/activity-details/pm-references-widget.vue';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import PmTaskForm from '~/tasks/components/molecule/task-form/pm-task-form.vue';

const emit = defineEmits(['close']);
const $t = inject('$t');
const $services = inject('$services');
const $date = inject('$date');

const route = useRoute();
const project_management_store = useProjectManagementStore();
const common_store = useCommonStore();

const { $g, active_task, references } = storeToRefs(project_management_store);
const { set_references, refetch_activity, update_activity_reference } = project_management_store;

const tab_items = computed(() => {
  return [
    {
      uid: 'FILE',
      name: 'FILE',
      label: $t('Files'),
      singular_label: $t('File'),
      create_component: markRaw(PmReferencesWidget),
      service_name: 'documents',
      delete_modal_header: $t('Delete File'),
    },
    {
      uid: 'TASK',
      name: 'TASK',
      label: $t('Tasks'),
      singular_label: $t('Task'),
      create_component: markRaw(PmTaskForm),
      service_name: 'tasks',
      delete_modal_header: $t('Delete Task'),
    },
    {
      uid: 'FORM',
      name: 'FORM',
      label: $t('Forms'),
      singular_label: $t('Form'),
      create_component: markRaw(PmNewForm),
      service_name: 'forms',
      delete_modal_header: $t('Delete Form'),
    },
  ];
});

const state = reactive({
  current_tab: tab_items.value[0],
  refresh_key: 0,
  is_loading: false,
});

const filtered_references = computed(() => {
  return references.value.filter(item => item.type === state.current_tab.uid);
});

const attachReference = async payload => await onReferencesUpdated(payload, 'add');

const activity_references = computed(() => {
  return active_task.value.references;
});

const additional_queries = computed(() => {
  if (state.current_tab.name === 'FORM') {
    return {
      all_access: true,
      is_child: true,
      public: false,
    };
  }
  else if (state.current_tab.name === 'TASK') {
    return {
      archived: false,
      asset_uid: route.params.asset_id,
    };
  }

  return {};
});

const restricted_items = computed(() => {
  return activity_references.value
    .filter(item => item.resource_type === state.current_tab.name)
    .map(item => item.resource_id);
});

async function triggerRefetchActivity() {
  await refetch_activity(active_task.value.uid);
  await set_references($t);
  $g.value.render();
  state.refresh_key++;
}

async function onReferencesUpdated(references, type_of_update) {
  try {
    const references_array = Array.isArray(references) ? references : [references];

    const type_of_update_pm = type_of_update === 'add' ? 'add' : 'delete';
    await update_activity_reference({
      activity: active_task.value,
      body: {
        [type_of_update_pm]: references_array.map(uid => ({
          resource_id: uid,
          resource_type: state.current_tab.name,
        })),
      },
    });
    if (state.current_tab.service_name === 'forms') {
      await $services[state.current_tab.service_name].post({
        body: {
          [state.current_tab.service_name]: {
            update: references_array.map(
              uid => ({
                references: {
                  [type_of_update]: [
                    {
                      resource_id: active_task.value.uid,
                      resource_type: 'PM_ACTIVITY',
                    },
                  ],
                },
                uid,
              }),
            ),
          },
        },
      });
    }
    else if (state.current_tab.service_name === 'tasks') {
      await $services[state.current_tab.service_name].patch({
        body: {
          [state.current_tab.service_name]: references_array.map(
            uid => ({
              references: {
                [type_of_update]: [
                  {
                    resource_id: active_task.value.uid,
                    resource_type: 'PM_ACTIVITY',
                  },
                ],
              },
              uid,
            }),
          ),
        },
      });
    }
    else if (state.current_tab.service_name === 'documents') {
      await $services[state.current_tab.service_name].post({
        body: {
          files: references_array.map(
            uid => ({
              references: {
                [type_of_update]: [
                  {
                    resource_uid: active_task.value.uid,
                    resource_type: 'PM_ACTIVITY',
                  },
                ],
              },
              uid,
            }),
          ),
        },
      });
    }
  }
  catch (error) {
    logger.error(error.message);
  }
  finally {
    await triggerRefetchActivity();
  }
}

function getStatusBackgroundColor(item) {
  if (item.type === 'TASK')
    return item.bg_color;
  else if (item.color)
    return `${item.color}1a`;
  else return '#6670851a';
}
</script>

<template>
  <HawkModalTemplate @close="emit('close')">
    <template #header_left>
      {{ $t('Add references') }}
    </template>
    <div class="flex flex-col w-[600px] h-[300px]">
      <HawkPageHeaderTabs
        :tabs="tab_items"
        :active_item="state.current_tab.uid"
        :space="4"
        @tab-click="state.current_tab = $event"
      />
      <HawkLoader v-if="state.is_loading" />
      <div v-else class="mt-6 scrollbar">
        <HawkIllustrations
          v-if="!filtered_references.length"
          type="no-data"
          :for="`${state.current_tab.uid.toLowerCase()}s`"
          variant="mini"
        />
        <PmListComponent v-else :key="state.refresh_key" :items="filtered_references" height-class="h-[70px]">
          <template #left_section="{ item }">
            <div class="text-sm font-medium text-gray-900">
              {{ item.name }}
            </div>
            <div class="flex items-center gap-1 text-xs font-medium text-gray-700">
              <HawkBadge v-if="item.category">
                {{ common_store.get_category(item.category)?.name }}
              </HawkBadge>
              <HawkMembers
                v-if="item.assignees.length"
                :members="item.assignees"
                :has_avatar="true"
                type="badge"
                size="xs"
              />
              <template v-if="item.due_date">
                {{ $date(item.due_date, 'Do MMM, YYYY') }}
              </template>
            </div>
          </template>
          <template #right_section="{ item }">
            <div class="flex items-center gap-3">
              <HawkBadge
                v-if="item.status"
                class="flex"
                :style="`
                  color: ${item.color};
                  background-color: ${getStatusBackgroundColor(item)}
                `"
              >
                {{ item.status }}
              </HawkBadge>
            </div>
          </template>
        </PmListComponent>
      </div>
      <div v-if="state.current_tab.name" class="mt-6">
        <component
          :is="state.current_tab.create_component"
          :additional_queries="additional_queries"
          :attach_reference="attachReference"
          :restricted_items="restricted_items"
          :refetch_activity="triggerRefetchActivity"
        />
      </div>
    </div>
    <template #footer>
      <div class="flex justify-end w-full">
        <HawkButton type="outlined" @click="$emit('close')">
          {{ $t('Done') }}
        </HawkButton>
      </div>
    </template>
  </HawkModalTemplate>
</template>

<style lang="scss" scoped>
:deep() {
  .forms-list-container {
    overflow-x: auto !important;
    table {
      width: 512px !important;
      max-width: 512px !important;
      min-width: unset !important;
    }
  }
}
</style>
