import { clone } from 'lodash-es';

export function getGanttColumns($t, is_published, track_resources, track_costs) {
  const published_dates_children = [
    {
      name: 'start_date',
      label: 'Start',
      type: 'date',
      align: 'center',
      min_width: 90,
      width: 130,
      resize: true,
    },
    {
      name: 'end_date',
      label: 'Finish',
      type: 'date',
      align: 'center',
      min_width: 90,
      width: 130,
      resize: true,
    },
    {
      name: 'planned_start',
      label: 'Planned Start',
      type: 'date',
      align: 'center',
      min_width: 80,
      resize: true,
    },
    {
      name: 'planned_finish',
      label: 'Planned Finish',
      type: 'date',
      align: 'center',
      min_width: 85,
      resize: true,
    },
  ];

  const unpublished_dates_children = [
    {
      name: 'start_date',
      label: 'Planned Start',
      type: 'date',
      align: 'center',
      min_width: 90,
      width: 130,
      resize: true,
    },
    {
      name: 'end_date',
      label: 'Planned Finish',
      type: 'date',
      align: 'center',
      min_width: 90,
      width: 130,
      resize: true,
    },
  ];

  return clone([
    {
      name: 'general',
      label: $t('General'),
      children: [
        {
          name: 'wbs',
          label: 'WBS',
          min_width: 65,
          width: 75,
          resize: true,
        },
        {
          name: 'text',
          label: 'Activity',
          type: 'text',
          min_width: 250,
          width: 300,
          resize: true,
          tree: true,
        },
        {
          name: 'id',
          label: 'ID',
          type: 'text',
          min_width: 55,
          resize: true,
        },
        ...(track_resources
          ? [{
              name: 'resources',
              label: 'Resources',
              type: 'resources',
              min_width: 55,
              resize: true,
            }]
          : []),
        {
          name: 'status',
          label: 'Status',
          type: 'text',
          min_width: 75,
          resize: true,
        },
        {
          name: 'predecessors',
          label: 'Predecessors',
          type: 'text',
          min_width: 75,
          resize: true,
        },
        {
          name: 'successors',
          label: 'Successors',
          type: 'text',
          min_width: 75,
          resize: true,
        },
        {
          name: 'is_milestone',
          label: 'Milestone',
          type: 'boolean',
          align: 'center',
          min_width: 75,
          resize: true,
        },
        {
          name: 'constraint_type',
          label: 'Constraint type',
          type: 'select',
          align: 'center',
          min_width: 150,
          resize: true,
        },
        {
          name: 'constraint_date',
          label: 'Constraint date',
          type: 'date',
          align: 'center',
          min_width: 150,
          resize: true,
        },
      ],
    },
    {
      name: 'dates',
      label: $t('Dates'),
      children: [
        {
          name: 'actual_start',
          label: 'Actual Start',
          type: 'date',
          align: 'center',
          min_width: 75,
          resize: true,
        },
        {
          name: 'actual_finish',
          label: 'Actual Finish',
          type: 'date',
          align: 'center',
          min_width: 80,
          resize: true,
        },
      ],
    },
    {
      name: 'durations',
      label: $t('Durations'),
      children: [
        {
          name: 'duration',
          label: 'Duration',
          type: 'number',
          align: 'center',
          min_width: 75,
          width: 80,
          resize: true,
        },
        {
          name: 'planned_duration',
          label: 'Planned Duration',
          type: 'number',
          align: 'center',
          min_width: 90,
          resize: true,
        },
        {
          name: 'actual_duration',
          label: 'Actual Duration',
          type: 'number',
          align: 'center',
          min_width: 95,
          resize: true,
        },
        {
          name: 'remaining_duration',
          label: 'Remaining Duration',
          type: 'number',
          align: 'center',
          min_width: 95,
          resize: true,
        },
        {
          name: 'absolute_remaining_duration',
          label: 'Remaining Schedule Duration',
          type: 'number',
          align: 'center',
          min_width: 95,
          resize: true,
        },
      ],
    },
    {
      name: 'percentages',
      label: $t('Percentages'),
      children: [
        {
          name: 'progress',
          label: 'Progress',
          type: 'number',
          align: 'center',
          min_width: 80,
          width: 100,
          resize: true,
        },
        {
          name: 'percent_work_complete',
          label: 'Work Complete',
          type: 'number',
          align: 'center',
          min_width: 95,
          resize: true,
        },
        {
          name: 'percent_schedule_complete',
          label: 'Schedule Complete',
          type: 'number',
          align: 'center',
          min_width: 95,
          resize: true,
        },
      ],
    },
    {
      name: 'critical',
      label: $t('Critical'),
      children: [
        {
          name: 'is_critical',
          label: 'Critical',
          type: 'boolean',
          align: 'center',
          min_width: 75,
          resize: true,
        },
        {
          name: 'total_slack',
          label: 'Total Slack',
          type: 'number',
          align: 'center',
          min_width: 75,
          resize: true,
        },
        {
          name: 'free_slack',
          label: 'Free Slack',
          type: 'number',
          align: 'center',
          min_width: 75,
          resize: true,
        },
      ],
    },
    ...(track_costs
      ? [{
          name: 'cost',
          label: $t('Cost'),
          children: [
            {
              name: 'cost',
              label: 'Cost',
              type: 'number',
              align: 'center',
              min_width: 75,
              resize: true,
            },
            {
              name: 'planned_cost',
              label: 'Planned Cost',
              type: 'number',
              align: 'center',
              min_width: 90,
              resize: true,
            },
            {
              name: 'actual_cost',
              label: 'Actual Cost',
              type: 'number',
              align: 'center',
              min_width: 95,
              resize: true,
            },
            {
              name: 'remaining_cost',
              label: 'Remaining Cost',
              type: 'number',
              align: 'center',
              min_width: 95,
              resize: true,
            },
          ],
        }]
      : []),
    {
      name: 'work',
      label: $t('Work'),
      children: [
        {
          name: 'work',
          label: 'Work',
          type: 'number',
          align: 'center',
          min_width: 75,
          resize: true,
        },
        {
          name: 'planned_work',
          label: 'Planned Work',
          type: 'number',
          align: 'center',
          min_width: 75,
          resize: true,
        },
        {
          name: 'actual_work',
          label: 'Actual Work',
          type: 'number',
          align: 'center',
          min_width: 75,
          resize: true,
        },
        {
          name: 'remaining_work',
          label: 'Remaining Work',
          type: 'number',
          align: 'center',
          min_width: 75,
          resize: true,
        },
        {
          name: 'work_rate',
          label: 'Work Rate',
          type: 'text',
          min_width: 75,
          resize: true,
        },
        {
          name: 'absolute_work_rate',
          label: 'Work Required (Per Day)',
          type: 'text',
          min_width: 75,
          resize: true,
        },
        {
          name: 'planned_work_rate',
          label: 'Work Rate (Planned)',
          type: 'text',
          min_width: 75,
          resize: true,
        },
        {
          name: 'actual_work_rate',
          label: 'Work Rate (Actual)',
          type: 'text',
          min_width: 75,
          resize: true,
        },
        {
          name: 'bl_work_rate',
          label: 'Work Rate (Baseline)',
          type: 'text',
          min_width: 75,
          resize: true,
        },
        {
          name: 'remaining_work_rate',
          label: 'Work Rate (Remaining)',
          type: 'text',
          min_width: 75,
          resize: true,
        },
      ],
    },
    {
      name: 'earned_values',
      label: $t('Earned Values'),
      children: [
        {
          name: 'planned_value',
          label: 'Planned Value',
          type: 'number',
          align: 'center',
          min_width: 75,
          resize: true,
        },
        {
          name: 'actual_value',
          label: 'Actual Value',
          type: 'number',
          align: 'center',
          min_width: 75,
          resize: true,
        },
        {
          name: 'earned_value',
          label: 'Earned Value',
          type: 'number',
          align: 'center',
          min_width: 75,
          resize: true,
        },
        {
          name: 'spi',
          label: 'SPI',
          type: 'number',
          align: 'center',
          min_width: 75,
          resize: true,
        },
        {
          name: 'cpi',
          label: 'CPI',
          type: 'number',
          align: 'center',
          min_width: 75,
          resize: true,
        },
        {
          name: 'sv',
          label: 'SV',
          type: 'number',
          align: 'center',
          min_width: 75,
          resize: true,
        },
        {
          name: 'cv',
          label: 'CV',
          type: 'number',
          align: 'center',
          min_width: 75,
          resize: true,
        },
      ],
    },
  ].map((column) => {
    if (column.name === 'dates' && is_published)
      column.children = [...published_dates_children, ...column.children];
    else if (column.name === 'dates')
      column.children = [...unpublished_dates_children, ...column.children];

    if (column.children) {
      column.children = column.children.map((child) => {
        return {
          ...child,
          label: $t(child.label),
        };
      });
    }

    return column;
  }));
}
